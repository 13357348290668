import {
  Grid,
  Box,
  List,
  ListItem,
  Typography,
  IconButton,
  MenuItem,
  Modal,
  Select,
  FormControl,
  TextareaAutosize,
} from "@mui/material";
import { ManagementServices } from "../services/management_services";
import React from "react";
import { ErrorAlert, SuccessAlert } from "./alert";
import { Emaillable, CustomField } from "../styles/loginstyles";
import { useForm } from "react-hook-form";
import {
  safetyMainHeading,
  safetyText,
  editIcon,
  deleteIcon,
  safetyBorder,
  safetyBlock,
  modalHeading,
  FaqModalStyle,
  Errors,
} from "../styles/common_styles";
import EditIcon from "../assets/icons/edit.svg";
import DeleteIcon from "../assets/icons/delete.svg";
import { BlockOrDeleteModalComponent } from "./modal_component";
import { BackDropLoader } from "./loader_component";
import { CommonServices } from "../services/common_services";
import { ButtonComponent } from "./form_components/button";

export const TermsAndConditionHandling = ({
  modType,
  countryId,
  stateId,
  cityId,
  handMod,
  handleModClose,
  resHandler,
  editModHandler
  
}) => {
  const [Termsandcondition, setTermsandcondition] = React.useState([]);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [handleModal, setHandleModal] = React.useState(handMod);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [actionId, setActionId] = React.useState("");
  const [countries, setCountries] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState(null);
  const [states, setStates] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState(null);
  const [cities, setCities] = React.useState([]);
  const [selectedCity, setSelectedCity] = React.useState(null);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [backDropLoaderOpen, setBackDropLoaderOpen] = React.useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const editHandling = (data) => {
    setActionId(data.id);
    setSelectedCountry(data.country)
    setSelectedState(data.state)
    setSelectedCity(data.city)
    // setValue('title',data.title)
    setValue('description',data.description)
    editModHandler(true)
    setIsEditMode(true)
};

  const GetTermsandConditions = async () => {
    const payload = {
      type: modType,
      countryId,
      stateId,
      cityId,
    };
    const TermsandConditions = await ManagementServices.TermsandConditions(
      payload
    );
    if (TermsandConditions.status === 1) {
      setTermsandcondition(TermsandConditions.data);
      // setAlrtMsg(TermsandConditions.message)
      // setSuccessAlrtOpen(true)
    } else {
      setAlrtMsg(TermsandConditions.message);
      setErrorAlrtOpen(true);
    }
  };

  

  const TermandConditionsAdding = async (data) => {
    const payload = {
      countryId:selectedCountry,
      stateId:selectedState,
      cityId:selectedCity,
      // title:data.title ,
      description:data.description ,
      type: modType,
    }
    let insertion;
    
      if(isEditMode !== true){
        insertion = await ManagementServices.inerstTermandConditionsAdding(
          payload
        );
        setAlrtMsg(insertion.message)
        setSuccessAlrtOpen(true)
      }else{
        payload.id = actionId
        insertion = await ManagementServices.editTermsandCondition(
          payload
        );
        setAlrtMsg(insertion.message)
        setSuccessAlrtOpen(true)
      }
    insertion && resHandler(insertion);
  };

  const blockOrDeleteHandleOpen = (id) => {
    setActionId(id);
    setDeleteModal(true);
  };

  const handleToggleBackDropLoader = () => {
    setBackDropLoaderOpen(!backDropLoaderOpen);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const DeleteModalClose = () => {
    setDeleteModal(false);
  };

  const handleRes = async (data) => {
    try {
      let response;
      if (data === 1) {
        DeleteModalClose();
      } else {
        setBackDropLoaderOpen(true);
          response = await ManagementServices.deleteTermsandConditions(
            actionId)
            if (response.status === 1) {
              setBackDropLoaderOpen(false);
              setAlrtMsg(response.message);
              setSuccessAlrtOpen(true);
              DeleteModalClose();
              GetTermsandConditions()
            } else {
              setBackDropLoaderOpen(false);
              setAlrtMsg(response.message);
              setErrorAlrtOpen(true);
            }
        } 
      
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const TermsandConditionsList =
    Termsandcondition &&
    Termsandcondition.map((item) => (
      <ListItem>
        <Grid container alignItems="center" sx={safetyBlock}>
          <Grid item md={12} component="h2" sx={safetyMainHeading}>
            {item.title}
          </Grid>
          <Grid item md={10} sx={safetyBorder}>
            <Typography sx={safetyText}>{item.description}</Typography>
          </Grid>
          <Grid item md={2} container justifyContent={"center"}>
            <IconButton
              sx={editIcon}
              onClick={() => editHandling(item)}
            >
              <img src={EditIcon} alt="edit" />
            </IconButton>
            <IconButton
              sx={[deleteIcon, modType === 3 && { display: "none" }]}
              onClick={() => blockOrDeleteHandleOpen(item.id)}
            >
              <img src={DeleteIcon} alt="delete" />
            </IconButton>
          </Grid>
        </Grid>
      </ListItem>
    ));

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async () => {
    try {
      const payload = {
        type: 2,
        country: selectedCountry,
      };
      const States = await CommonServices.getLocation(payload);

      if (States.status === 1) {
        setStates(States.data);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetCities = async (country) => {
    try {
      const payload = {
        type: 3,
        country: selectedCountry,
        state: selectedState,
      };
      const Cities = await CommonServices.getLocation(payload);

      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    setSelectedState(null);
    setSelectedCity(null);
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    setSelectedCity(null);
  };

  const handleChangeCity = (event) => {
    const { value } = event.target;
    setSelectedCity(value);
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalCities = cities.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  React.useEffect(() => {
    if(selectedCountry!== null) GetStates();
    
  }, [selectedCountry]);

  React.useEffect(() => {
    if(selectedState!== null) GetCities();
  }, [selectedState]);

  React.useEffect(() => {
    GetTermsandConditions();
    
  }, [modType, countryId, stateId, cityId]);

  React.useEffect(() => {
    setHandleModal(handMod);
     GetTermsandConditions()
    if (handMod) {
      GetCountries()
    }else{
      setIsEditMode(false);
      setSelectedCountry(null);
      setSelectedState(null);
      setSelectedCity(null);
      // setValue('title','');
      setValue('description','');
    }
  }, [handMod]);


  return (
    <>
      <BackDropLoader
        backDropLoaderopen={backDropLoaderOpen}
        BackDropLoaderHandleClose={handleToggleBackDropLoader}
      />
      <Box>
        <List>
          {TermsandConditionsList.length > 0
            ? TermsandConditionsList
            : <Grid component={'h2'} textAlign={'center'}>No Data Found</Grid>}
        </List>
      </Box>
      <Modal
        open={handleModal}
        onClose={handleModClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={FaqModalStyle}>
          <Typography sx={modalHeading}>
            {modType === 1
              ? isEditMode
                ? " Edit Consumer Terms & Conditions"
                : "Add Consumer Terms & Conditions"
              : isEditMode
              ? " Edit Chef Terms & Conditions"
              : "Add Chef Terms & Conditions"}
          </Typography>
          <Grid
            component={"form"}
            onSubmit={handleSubmit(TermandConditionsAdding)}
          >
            <Grid container justifyContent={"center"} spacing={3}>
            {(modType === 1 || modType === 2) && (
              <>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Country</Grid>
                  <Select
                    sx={CustomField}
                    value={selectedCountry}
                    // name='country'
                    size={"small"}
                    {...register("countryId")}
                    onChange={handleChangeCountry}
                    displayEmpty
                    inputProps={{
                      "aria-label": "countryId",
                      sx: { "& input": { color: "#CCCCCC" } },
                    }}
                    // helperText={errors?.country?.message}
                  >
                    <MenuItem value={null} disabled>
                      Select country
                    </MenuItem>
                    {totalCountries}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>State</Grid>
                  <Select
                    sx={CustomField}
                    value={selectedState}
                    // name='country'
                    size={"small"}
                    {...register("stateId")
                  }
                    onChange={handleChangeState}
                    displayEmpty
                    inputProps={{
                      "aria-label": "stateId",
                      sx: { "& input": { color: "#CCCCCC" } },
                    }}
                  >
                    <MenuItem value={null} disabled>
                      Select state
                    </MenuItem>
                    {totalStates}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>City</Grid>
                  <Select
                    sx={CustomField}
                    value={selectedCity}
                    // name='country'
                    size={"small"}
                    {...register("cityId")}
                    onChange={handleChangeCity}
                    displayEmpty
                    inputProps={{
                      "aria-label": "cityId",
                      sx: { "& input": { color: "#CCCCCC" } },
                    }}
                  >
                    <MenuItem value={null} disabled>
                      Select city
                    </MenuItem>
                    {totalCities}
                  </Select>
                </FormControl>
              </Grid>
              </>
            )}
              {/* <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Title</Grid>
                  <TextField
                    size="small"
                    fullWidth
                    placeholder="Type your Title"
                    {...register("title", {
                      required: true,
                    })}
                  />
                  {errors.title && errors.title.type === "required" && (
                    <Typography sx={Errors}>
                      Oops! Please enter Title.
                    </Typography>
                  )}
                </FormControl>
              </Grid> */}
              <Grid item md={10}>
                <FormControl fullWidth>
                  <Grid sx={Emaillable}>Description</Grid>
                  <TextareaAutosize
                    className={"textArea"}
                    // name={'description'}
                    // value={onBoardingDetails.description}
                    placeholder="Type your Terms and conditions"
                    {...register("description", {
                      required: true,
                      // maxLength: {
                      //   value: 1000,
                      //   message: 'Description should be less than 1000 characters',
                      // },
                    })}
                    // onChange={handleOnBoardingChanging}
                  />
                  {errors.description &&
                    (
                      <Typography sx={Errors}>
                      {errors.description.message || "Oops! Please enter Terms & Conditions"}  
                      </Typography>
                    )}
                </FormControl>
              </Grid>
              <Grid container justifyContent={"center"} sx={{padding: '20px'}}>
              {isEditMode ? <ButtonComponent type={"submit"} btnText={"Update"} />
                :<ButtonComponent type={"submit"} btnText={"ADD"} />}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <BlockOrDeleteModalComponent
        deleteopen={deleteModal}
        resType={handleRes}
        onClose={DeleteModalClose}
        title={"Do you want to Delete ?"}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
    </>
  );
};
