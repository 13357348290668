import React, { useEffect } from "react";
import { Container, Grid,Typography } from "@mui/material";
import { ManagementServices } from "../../services/management_services";

export const InformativePrivacyPolicy = () => {
    const [privacyPolicy, setPrivacyPolicy] = React.useState([]);
    const getPrivacyPolicy = async () => {
        const payload ={
            type:2
        }
        try {
           const response = await ManagementServices.InformativePrivacyPloicy(payload);
           if(response.status === 1){
               
               setPrivacyPolicy(response.data[0].description)
               
           }

        }
        catch (error) {
            console.error(`Error occured while fetching roles:${error}`);
        }
    }
    useEffect(() => {
        getPrivacyPolicy();   
    },[])
    
    return (
        <>   
        <Container maxWidth="lg" sx={{mt:5}}>  
            <Grid>
                <h1 align="center" gutterBottom>
                    Privacy Policy
                </h1>
                <Typography variant="body1" sx={{ whiteSpace: "pre-line",mt:5 }}>
                    {privacyPolicy}
                </Typography>
            </Grid> 
        </Container>
         
        </>
    )
};