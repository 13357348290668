import * as React from "react";
import { Grid, Tab, Select, FormControl, MenuItem } from "@mui/material";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import { TitleComponent } from "../../components/title_component";
import {
  tabStyle,
  tabItem,
  tabHeader,
  onboardingTab,
} from "../../styles/common_styles";
import { CommonServices } from "../../services/common_services";
import { ErrorAlert } from "../../components/alert";
import { PrivacyPolicyHandling } from "../../components/privacy_policy_handling";
import { ButtonComponent } from "../../components/form_components/button";

const PrivacyPolicy = () => {
  const [value, setValue] = React.useState(1);
  const [countries, setCountries] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState(0);
  const [states, setStates] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState(0);
  const [cities, setCities] = React.useState([]);
  const [selectedCity, setSelectedCity] = React.useState(0);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleModalOpen = () => setModalOpen(true);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleRes = (data) => {
    if (data.status === 1) {
      setAlrtMsg(data.message);
      setModalOpen(false);
    } else {
      setAlrtMsg(data.message);
      setErrorAlrtOpen(true);
    }
  };

  const handleEdit = (data) => {
    setModalOpen(data);
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setSelectedCountry(0);
    setSelectedState(0);
    setSelectedCity(0);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    setSelectedCountry(value);
    setSelectedState(0);
    setSelectedCity(0);
  };

  const handleChangeState = (event) => {
    const { value } = event.target;
    setSelectedState(value);
    setSelectedCity(0);
  };

  const handleChangeCity = (event) => {
    const { value } = event.target;
    setSelectedCity(value);
  };

  const GetCountries = async () => {
    try {
      const payload = {
        type: 1,
      };
      const Countries = await CommonServices.getLocation(payload);

      if (Countries.status === 1) {
        setCountries(Countries.data);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(Countries.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetStates = async () => {
    try {
      const payload = {
        type: 2,
        country: selectedCountry,
      };
      const States = await CommonServices.getLocation(payload);

      if (States.status === 1) {
        setStates(States.data);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(States.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const GetCities = async (country) => {
    try {
      const payload = {
        type: 3,
        country: selectedCountry,
        state: selectedState,
      };
      const Cities = await CommonServices.getLocation(payload);

      if (Cities.status === 1) {
        setCities(Cities.data);
      } else {
        setErrorAlrtOpen(true);
        setAlrtMsg(Cities.message);
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const totalCountries = countries.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalStates = states.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  const totalCities = cities.map((x) => (
    <MenuItem key={x.id} value={x.id}>
      {x.name}
    </MenuItem>
  ));

  React.useEffect(() => {
    GetCountries();
  }, []);

  React.useEffect(() => {
    if(selectedCountry !== 0) {
      GetStates();
    }
  }, [selectedCountry]);

  React.useEffect(() => {
    if(selectedState !== 0) {
      GetCities();
    }
  }, [selectedState]);

  return (
    <>
      <Grid
        container
        justifyContent={"space-around"}
        alignItems="center"
        spacing={2}
        p={3}
        sx={tabHeader}
      >
        <Grid item md={3}>
          <TitleComponent title={"Privacy Policy"} />
        </Grid>
        <Grid item md={4}>
          <TabContext value={value}>
            <TabList
              sx={tabStyle}
              onChange={handleChangeTab}
              className="tabList"
            >
            
              <Tab label="Consumer" value={1}sx={tabItem} />
              <Tab label="Chef" value={2} sx={tabItem} />
              <Tab label="Informative" value={3}sx={tabItem} />
            </TabList>
          </TabContext>
        </Grid>
        <Grid item md={5}>
          <Grid container justifyContent={"end"} spacing={2}>
            <Grid item md={3}>
              <FormControl fullWidth>
                <Select
                  value={selectedCountry}
                  size={"small"}
                  inputProps={{ "aria-label": "Country" }}
                  onChange={handleChangeCountry}
                  disabled={value === 3}
                >
                  <MenuItem value={0}>Select Country</MenuItem>
                  {totalCountries}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl fullWidth>
                <Select
                  value={selectedState}
                  size={"small"}
                  inputProps={{ "aria-label": "Country" }}
                  onChange={handleChangeState}
                  disabled={value === 3}
                >
                  <MenuItem value={0}>Select State</MenuItem>
                  {totalStates}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <FormControl fullWidth>
                <Select
                  value={selectedCity}
                  size={"small"}
                  inputProps={{ "aria-label": "Country" }}
                  onChange={handleChangeCity}
                  disabled={value === 3}

                >
                  <MenuItem value={0}>Select City</MenuItem>
                  {totalCities}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={3}>
              <ButtonComponent btnText={"ADD"} onClick={handleModalOpen}                   disabled={value === 3}
             />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* TabPanel component moved after the parent Grid */}
      <TabContext value={value}>
        <TabPanel value={value} sx={onboardingTab}>
          <PrivacyPolicyHandling
            modType={value}
            countryId={selectedCountry}
            stateId={selectedState}
            cityId={selectedCity}
            handMod={modalOpen}
            handleModClose={handleModalClose}
            resHandler={handleRes}
            editModHandler = {handleEdit}
            disabled={value === 3}
          />
        </TabPanel>
      </TabContext>
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </>
  );
};

export default PrivacyPolicy;
