import { addButton, ButtonLarge } from "../../styles/common_styles";
import { Button } from "@mui/material";

export const ButtonComponent = ({ btnText, buttonSize, type, onClick, disabled }) => {
  
  let buttonClass;

  if (buttonSize === "large") {
    buttonClass = ButtonLarge;
  } else {
    buttonClass = addButton;
  }
  return (
    <>
      <Button type={type} onClick={onClick} sx={buttonClass} disabled={disabled}>
        {btnText}
      </Button>
    </>
  );
};