import { Box, Grid, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  SpanStyle,
  persondetailsContainer,
  imgmodalStyles,
} from "../../styles/common_styles";
import { ManagementServices } from "../../services/management_services";
import { PdfCard } from "../../components/pdf_card";
import { SuccessAlert } from "../../components/alert";
import { ErrorAlert } from "../../components/alert";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadSharpIcon from "@mui/icons-material/DownloadSharp";
import AvatarImg from "../../assets/images/avatar.svg";
import { Loader } from "../../components/loader_component";

export const PersonalDetails = ({ personId, fromModule }) => {
 
  const [userDetails, setUserDetails] = React.useState({});
  const [onboardingDetails, setOnboardingDetails] = React.useState([]);
  const [totalCount, setTotalcount] = useState(0);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [completedOnboarding, setCompltedonboarding] = useState(0);
  const [imgModal, setImgModal] = useState(false);
  const [imgLink, setImglink] = useState("");
  const [loaderData, setLoaderData] = React.useState(true);

  const getUserDetails = async () => {
    try {
      if (fromModule === "1" || fromModule === 1) {
        const details = await ManagementServices.consumerAboutDetails(personId);
        if (details.status === 1) {
          setUserDetails(details.data.consumerDetails);
          setOnboardingDetails(details.data.onboardingDetails);
          setTotalcount(details.data.totalOnboardings);
          setCompltedonboarding(details.data.completedOnboardings);
          setLoaderData(false);
          // setAlrtMsg(details.message);
          // setSuccessAlrtOpen(true);
        } else {
          setAlrtMsg(details.message);
          setErrorAlrtOpen(true);
        }
        return;
      }
      const details = await ManagementServices.chefAboutDetails(personId);
      if (details.status === 1) {
        setUserDetails(details.data.chefDetails);
        setOnboardingDetails(details.data.onboardingDetails);
        setTotalcount(details.data.totalOnboardings);
        setCompltedonboarding(details.data.completedOnboardings);
        setLoaderData(false);
        // setAlrtMsg(details.message);
        // setSuccessAlrtOpen(true);
      } else {
        setAlrtMsg(details.message);
        setErrorAlrtOpen(true);
      }
      return;
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const openImgModal = (address) => {
    setImgModal(true);
    setImglink(address);
  };

  const closeImgModal = () => {
    setImgModal(false);
    setImglink("");
  };

  const renderImgModal = () => {
    return (
      <Modal
        open={imgModal}
        onClose={closeImgModal}
        aria-labelledby="sample-modal-title"
        aria-describedby="sample-modal-description"
      >
        <Box sx={imgmodalStyles}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={imgLink}
              alt="Avatar"
              style={{
                height: "380px",
                width: "500px",
                borderRadius: "20px",
                marginTop: "20px",
                objectFit: "cover",
              }}
            />
          </Grid>
        </Box>
      </Modal>
    );
  };

  const DownloadPdf = (pdfbase) => {
    const pdfPath = pdfbase;
    try {
      const link = document.createElement("a");
      link.href = pdfPath;
      link.download = pdfbase;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  React.useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <Box>
      {loaderData && loaderData ? (
        <Loader />
      ) : (
        <>
          <Grid sx={{ display: "flex", flexDirection: "row" }}>
            <Grid container>
              <Grid item sx={SpanStyle} md={4} mt={2}>
                {" "}
                User Name
              </Grid>
              <Grid item md={1} mt={2}>
                :
              </Grid>
              <Grid item md={7} mt={2}>
                {userDetails.user_name}
              </Grid>
              <Grid item sx={SpanStyle} md={4} mt={2}>
                {" "}
                First Name
              </Grid>
              <Grid item md={1} mt={2}>
                :
              </Grid>
              <Grid item md={7} mt={2}>
                {userDetails.first_name}
              </Grid>
              <Grid item sx={SpanStyle} md={4} mt={2}>
                {" "}
                Last Name
              </Grid>
              <Grid item md={1} mt={2}>
                :
              </Grid>
              <Grid item md={7} mt={2}>
                {userDetails.last_name}
              </Grid>
              <Grid item sx={SpanStyle} md={4} mt={2}>
                Phone No
              </Grid>
              <Grid item md={1} mt={2}>
                :
              </Grid>
              <Grid item md={7} mt={2}>
                {userDetails.mobile}
              </Grid>
              <Grid item sx={SpanStyle} md={4} mt={2}>
                Email Id
              </Grid>
              <Grid item md={1} mt={2}>
                :
              </Grid>
              <Grid item md={7} mt={2}>
                {userDetails.email}
              </Grid>
              <Grid item sx={SpanStyle} md={4} mt={2}>
                Address
              </Grid>
              <Grid item md={1} mt={2}>
                :
              </Grid>
              <Grid item md={7} mt={2}>
                {userDetails.address}
              </Grid>
              {(fromModule !== "1") && (
                <>
                  <Grid item sx={SpanStyle} md={4} mt={2}>
                    Address Proof
                  </Grid>
                  <Grid item md={1} mt={2}>
                    :
                  </Grid>
                  {userDetails && userDetails.chef_address_proof ? (
                    <Grid
                      item
                      md={5}
                      mr={20}
                      mt={2}
                      sx={persondetailsContainer}
                    >
                      <Grid>
                        <img
                          src={userDetails.chef_address_proof}
                          alt="Avatar"
                          style={{
                            height: "120px",
                            width: "fit-content",
                            borderRadius: "20px",
                            marginTop: "10px",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            openImgModal(userDetails.chef_address_proof)
                          }
                        />
                      </Grid>
                      <Grid>
                        {userDetails && userDetails.address_proof && (
                          <Grid>
                            <RemoveRedEyeIcon
                              className="viewIconn"
                              sx={{ marginRight: "20px", cursor: "pointer" }}
                              onClick={() =>
                                openImgModal(userDetails.chef_address_proof)
                              }
                            />
                            <DownloadSharpIcon
                              className="viewIconn"
                              onClick={() =>
                                DownloadPdf(userDetails.chef_address_proof)
                              }
                              sx={{ cursor: "pointer" }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item md={5} mr={20} mt={2}></Grid>
                  )}
                </>
              )}
              {/* <Grid item sx={SpanStyle} md={3} mt={2}>
                On Boarding ({completedOnboarding}/{totalCount})
              </Grid>
              <Grid item md={1} mt={2}>
                :
              </Grid>
              <Grid item md={7} mt={2}>
                <PdfCard pdfDetails={onboardingDetails} />
              </Grid> */}
            </Grid>
            <Grid>
              <Grid container>
                <Grid item sx={SpanStyle} md={4} mt={2}>
                  Country
                </Grid>
                <Grid item md={1} mt={2}>
                  :
                </Grid>
                <Grid item md={7} mt={2}>
                  {userDetails.country}
                </Grid>
                <Grid item sx={SpanStyle} md={4} mt={2}>
                  {" "}
                  State
                </Grid>
                <Grid item md={1} mt={2}>
                  :
                </Grid>
                <Grid item md={7} mt={2}>
                  {userDetails.state}
                </Grid>
                <Grid item sx={SpanStyle} md={4} mt={2}>
                  {" "}
                  City
                </Grid>
                <Grid item md={1} mt={2}>
                  :
                </Grid>
                <Grid item md={7} mt={2}>
                  {userDetails.city}
                </Grid>
                {fromModule === "1" || fromModule === 1 ? (
                  <>
                    <Grid item sx={SpanStyle} md={4} mt={2}>
                      Area Code
                    </Grid>
                    <Grid item md={1} mt={2}>
                      :
                    </Grid>
                    <Grid item md={7} mt={2}>
                      {userDetails.area_code}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item sx={SpanStyle} md={4} mt={2}>
                      Area Code
                    </Grid>
                    <Grid item md={1} mt={2}>
                      :
                    </Grid>
                    <Grid item md={7} mt={2}>
                      {userDetails.area_code}
                    </Grid>
                  </>
                )}
                {fromModule !== "1" && (
                  <>
                    <Grid item sx={SpanStyle} md={4} mt={2}>
                      Kitchen Name
                    </Grid>
                    <Grid item md={1} mt={2}>
                      :
                    </Grid>
                    <Grid item md={7} mt={2}>
                      {userDetails.kitchen_name}
                    </Grid>
                  </>
                )}
                {fromModule !== "1" && (
                  <>
                    <Grid item sx={SpanStyle} md={4} mt={2}>
                      Kitchen Address
                    </Grid>
                    <Grid item md={1} mt={2}>
                      :
                    </Grid>
                    <Grid item md={7} mt={2}>
                      {userDetails.kitchen_address}
                    </Grid>
                  </>
                )}
                {fromModule !== "1" && (
                  <>
                    <Grid item sx={SpanStyle} md={4} mt={2}>
                      Bio
                    </Grid>
                    <Grid item md={1} mt={2}>
                      :
                    </Grid>
                    <Grid item md={7} mt={2}>
                      {userDetails.about}
                    </Grid>
                  </>
                )}
                {fromModule !== "1" && (
                  <>
                    <Grid item sx={SpanStyle} md={4} mt={2}>
                      Kitchen Type
                    </Grid>
                    <Grid item md={1} mt={2}>
                      :
                    </Grid>
                    <Grid item md={7} mt={2}>
                      {userDetails.kitchen_type === 1
                        ? "Residential"
                        : "Commercial"}
                    </Grid>
                  </>
                )}
                {(fromModule !== "1") && (
                  <>
                    <Grid item sx={SpanStyle} md={4} mt={2}>
                      Kitchen Address Proof
                    </Grid>
                    <Grid item md={1} mt={2}>
                      :
                    </Grid>
                    {userDetails && userDetails.kitchen_address_proof ? (
                      <Grid item md={4} mt={2} sx={persondetailsContainer}>
                        <Grid>
                          <img
                            src={userDetails.kitchen_address_proof}
                            alt="Avatar"
                            style={{
                              height: "120px",
                              width: "fit-content",
                              borderRadius: "20px",
                              marginTop: "10px",
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              openImgModal(userDetails.kitchen_address_proof)
                            }
                          />
                        </Grid>
                        <Grid>
                          {userDetails && userDetails.kitchen_address_proof && (
                            <Grid>
                              <RemoveRedEyeIcon
                                className="viewIconn"
                                sx={{ marginRight: "20px", cursor: "pointer" }}
                                onClick={() =>
                                  openImgModal(
                                    userDetails.kitchen_address_proof
                                  )
                                }
                              />
                              <DownloadSharpIcon
                                className="viewIconn"
                                onClick={() =>
                                  DownloadPdf(userDetails.kitchen_address_proof)
                                }
                                sx={{ cursor: "pointer" }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item md={5} mr={20} mt={2}></Grid>
                    )}
                  </>
                )}
                {/* <Grid container spacing={2} mt={2}>
                  {onboardingDetails?.map((detail, index) => (
                    <Grid container key={index} sx={{ marginBottom: "16px" }}>
                      
                      {detail.attachmentUrl ? (
                        <>
                          <Grid item sx={SpanStyle} md={4} mt={2}>
                            Attachment {index + 1}
                          </Grid>
                          <Grid item md={1} mt={2}>
                            :
                          </Grid>
                          <Grid item md={4} mt={2} sx={persondetailsContainer}>
                           
                            <img
                              src={userDetails.kitchen_address_proof}
                              alt="Avatar"
                              style={{
                                height: "120px",
                                width: "fit-content",
                                borderRadius: "8px",
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                openImgModal(userDetails.kitchen_address_proof)
                              }
                            />
                            <Grid
                              sx={{
                                marginLeft: "16px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <RemoveRedEyeIcon
                                className="viewIconn"
                                sx={{ marginRight: "20px", cursor: "pointer" }}
                                onClick={() =>
                                  openImgModal(detail.attachmentUrl)
                                }
                              />
                              <DownloadSharpIcon
                                className="downloadIconn"
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  DownloadPdf(detail.attachmentUrl)
                                }
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <Grid item md={12} mt={2}>
                          <p>
                            No attachment available for onboarding {index + 1}.
                          </p>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid> */}
              </Grid>
            </Grid>
            <Grid>
              <Grid item md={1} mt={2}>
                {userDetails && userDetails.profile ? (
                  <img
                    src={userDetails.profile}
                    alt="Avatar"
                    style={{
                      height: "80px",
                      width: "80px",
                      borderRadius: "100px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                    src={AvatarImg}
                    alt="Avatar"
                    style={{
                      height: "80px",
                      width: "80px",
                      borderRadius: "100px",
                      objectFit: "cover",
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item sx={SpanStyle} md={2} mt={2}>
              On Boarding ({completedOnboarding}/{totalCount})
            </Grid>
            <Grid item md={1} mt={2}>
              :
            </Grid>
            <Grid item md={9}>
              <PdfCard pdfDetails={onboardingDetails} />
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item sx={SpanStyle} md={2} mt={2}>
              Attachments
            </Grid>
            <Grid item md={1} mt={2}>
              :
            </Grid>
            <Grid item md={9}>
              <Grid className="PdfCards">
                {onboardingDetails?.map((detail, index) =>
                  detail.attachmentUrl ? (
                    <Grid key={index} className="AddressCard">
                      <Typography variant="p" className="RuleNos">
                        Attachment {index + 1}
                      </Typography>
                      <Grid className="CardBody">
                        <img
                          src={detail.attachmentUrl}
                          alt="Attachment"
                          className="AttachmentImg"
                        />
                        <Grid className="CardInnerIcons">
                          <RemoveRedEyeIcon
                            className="viewIcon"
                            onClick={() => openImgModal(detail.attachmentUrl)}
                          />
                          <DownloadSharpIcon
                            className="viewIcon"
                            onClick={() => DownloadPdf(detail.attachmentUrl)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <p>No attachment available for onboarding {index + 1}.</p>
                    </>
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {renderImgModal()}
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
    </Box>
  );
};
