import React from "react";
import { Modal, Box, Grid, Typography, IconButton } from "@mui/material";
import { modalStyle, pdfLink } from "../styles/common_styles";
import Rating from "@mui/material/Rating";
import {
  OrderStyle,
  SpanStyle,
  OrderTotalStyle,
} from "../styles/common_styles";
import { ManagementServices } from "../services/management_services";
import { Loader } from "./loader_component";
import CloseIcon from "@mui/icons-material/Close";

const OrderModalComponent = ({
  openhandler,
  handleClose,
  orderId,
  orderMessage,
  orderReportDate,
}) => {
  const [orderInvoiceDetails, setOrderInvoiceDetails] = React.useState({});
  const [loaderData, setLoaderData] = React.useState(true);

  const getInvoiceDetails = async () => {
    if (!orderId) return;
    const payload = {
      orderId: orderId,
    };
    const orderresponse =
      await ManagementServices.getconsumerOrderInvoiceDetails(payload);
    if (orderresponse.status === 1) {
      setOrderInvoiceDetails(orderresponse.data);
      setLoaderData(false);
    }
  };

  const totalAmount =
    orderInvoiceDetails?.food_details?.reduce((total, row) => {
      return (
        total +
        parseInt(row.final_price) * row.quantity +
        orderInvoiceDetails.tax +
        orderInvoiceDetails?.tip +
        orderInvoiceDetails.delivery_fee
      );
    }, 0) - orderInvoiceDetails.rewards;

  const totalAmountt = orderInvoiceDetails?.food_details?.reduce(
    (total, row) => {
      const price =
        orderInvoiceDetails?.order_type === 2 ? row?.final_price : row?.price;
      return total + price;
    },
    0
  );

  const totalAmounttforInstant = orderInvoiceDetails?.food_details?.reduce(
    (total, row) => {
      const price =
        orderInvoiceDetails?.order_type === 2 ? row?.final_price : row?.price;
      return total + row?.final_price * row?.quantity;
    },
    0
  );

  const getStatusName = (statusNumber) => {
    const colors = [
      "#FF5733",
      "#3357FF",
      "#3357FF",
      "#FF33A8",
      "#A833FF",
      "#33A8FF",
      "#FF3333",
      "#33A8FF",
      "#A8FF33",
    ];

    const statusMap = {
      1: { name: "Pending", color: colors[0] },
      // 1: { name: "Accepted", color: colors[1] },
      // 2: { name: "Rejected", color: colors[2] },
      3: { name: "Started", color: colors[3] },
      4: { name: "Prepared", color: colors[4] },
      5: { name: "Ready to deliver/pickup", color: colors[5] },
      6: { name: "Delivered", color: "green" },
      7: { name: "Cancelled", color: colors[7] },
      8: { name: "Missed(AutoCancel)", color: colors[8] },
      9: { name: "Quote Accepted", color: "green" },
    };

    return statusMap[statusNumber] || { name: "Unknown", color: "#CCCCCC" };
  };

  function formatCreatedAtToGMT(created_at) {
    const date = new Date(created_at);

    // Format the date to GMT string
    const gmtDateString = new Intl.DateTimeFormat("en-US", {
      timeZone: "GMT",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }).format(date);
    // Extract the date part and time part
    const [datePart, timePart] = gmtDateString.split(", ");

    return `${datePart},${timePart}`;
  }
  const getQuantityTypeText = (type) => {
    switch (type) {
      case 1:
        return "portions";
      case 2:
        return "other";
      case 3:
        return "other";
      default:
        return "";
    }
  };

  React.useEffect(() => {
    getInvoiceDetails();
  }, [orderId]);

  return (
    <Modal
      open={openhandler}
      onClose={handleClose}
      aria-labelledby="sample-modal-title"
      aria-describedby="sample-modal-description"
    >
      <Box sx={modalStyle}>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            m: 0.5,
            height: "2vh",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ color: "black", backgroundColor: "grey" }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        {Object.keys(orderInvoiceDetails).length !== 0 ? (
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              boxShadow: "0px 20px 30px 0px rgba(211, 209, 216, 0.251)",
              gap: 1,
              padding: 4,
              justifyContent: "center",
            }}
          >
            {loaderData ? (
              <Loader />
            ) : (
              <>
                <Grid item md={5} mr={10}>
                  <Grid container>
                    <Grid item>
                      <img
                        src={orderInvoiceDetails.chef_profile}
                        style={{
                          height: "100px",
                          width: "100px",
                          borderRadius: "100px",
                        }}
                        alt=""
                      />
                    </Grid>
                    <Grid item>
                      <Grid pt={1} pl={2}>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          {orderInvoiceDetails.chef_username}
                        </Typography>
                      </Grid>
                      <Grid pl={2}>
                        <Typography variant="h6">
                          {orderInvoiceDetails.chef_kitchen_name}
                        </Typography>
                      </Grid>
                      <Grid pl={2}>
                        <Rating
                          name="half-rating"
                          value={parseFloat(orderInvoiceDetails.chef_ratings)}
                          precision={0.5}
                          readOnly
                        />
                      </Grid>
                    </Grid>
                    <Grid mt={4} fontWeight={"bold"}>
                      Order Status
                    </Grid>
                    <Grid
                      mt={4}
                      color={
                        getStatusName(orderInvoiceDetails.order_status).color
                      }
                      pl={4}
                    >
                      : {getStatusName(orderInvoiceDetails.order_status).name}
                    </Grid>
                  </Grid>
                  <Grid mt={8} mb={6}>
                    --------------------------------
                  </Grid>

                  <Grid container>
                    <Grid item md={5} mt={2} sx={SpanStyle}>
                      Consumer Name
                    </Grid>
                    <Grid item md={1} mt={2}>
                      :
                    </Grid>
                    <Grid item md={6} mt={2}>
                      {orderInvoiceDetails.consumer_name}
                    </Grid>
                  </Grid>
                  {orderInvoiceDetails &&
                    orderInvoiceDetails?.food_details?.map((item, index) => {
                      return (
                        <Grid container>
                          <Grid item md={5} mt={2} sx={SpanStyle}>
                            Item {index + 1}
                          </Grid>
                          <Grid item md={1} mt={2}>
                            :
                          </Grid>
                          <Grid item md={6} mt={2}>
                            {item.food_name} X {item.quantity}
                          </Grid>
                        </Grid>
                      );
                    })}
                  {orderInvoiceDetails.order_type === 2 &&
                    orderInvoiceDetails.food_details.map((item, index) => {
                      return (
                        <Grid container key={index}>
                          <Grid item md={5} mt={2} sx={SpanStyle}>
                            Quantity Type
                          </Grid>
                          <Grid item md={1} mt={2}>
                            :
                          </Grid>
                          <Grid item md={6} mt={2}>
                            {getQuantityTypeText(item.quantity_type)}
                          </Grid>
                        </Grid>
                      );
                    })}
                  {orderInvoiceDetails &&
                    orderInvoiceDetails.food_details.map((item, index) => {
                      if (item.quantity_type === 3) {
                        return (
                          <Grid container key={index}>
                            <Grid item md={5} mt={2} sx={SpanStyle}>
                              Description
                            </Grid>
                            <Grid item md={1} mt={2}>
                              :
                            </Grid>
                            <Grid item md={6} mt={2}>
                              {item.description}
                            </Grid>
                          </Grid>
                        );
                      }
                      return null; // Return null for items that don't match the condition
                    })}
                  {orderInvoiceDetails.order_type === 2 && (
                    <Grid container>
                      <Grid item md={5} mt={2} sx={SpanStyle}>
                        Requested Date
                      </Grid>
                      <Grid item md={1} mt={2}>
                        :
                      </Grid>
                      <Grid item md={6} mt={2}>
                        {orderInvoiceDetails.requested_date || "N/A"},
                        {orderInvoiceDetails.requested_time}
                      </Grid>
                    </Grid>
                  )}
                  {orderInvoiceDetails.order_type === 2 && (
                    <Grid container>
                      <Grid item md={5} mt={2} sx={SpanStyle}>
                        Chef Proposed Time
                      </Grid>
                      <Grid item md={1} mt={2}>
                        :
                      </Grid>
                      <Grid item md={6} mt={2}>
                        {orderInvoiceDetails?.requested_time || "N/A"}
                      </Grid>
                    </Grid>
                  )}
                  <Grid container>
                    <Grid item md={5} mt={2} sx={SpanStyle}>
                      Order Type
                    </Grid>
                    <Grid item md={1} mt={2}>
                      :
                    </Grid>
                    <Grid item md={6} mt={2}>
                      {orderInvoiceDetails.order_type === 1
                        ? "Instant Order"
                        : "Food Request"}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={5} mt={2} sx={SpanStyle}>
                      Delivery Type
                    </Grid>
                    <Grid item md={1} mt={2}>
                      :
                    </Grid>
                    <Grid item md={6} mt={2}>
                      {orderInvoiceDetails.delivery_type === 1
                        ? "Pickup"
                        : orderInvoiceDetails.delivery_type === 2
                        ? "Delivery"
                        : "Uber"}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={5} mt={2} sx={SpanStyle}>
                      Order Mode
                    </Grid>
                    <Grid item md={1} mt={2}>
                      :
                    </Grid>
                    <Grid item md={6} mt={2}>
                      {orderInvoiceDetails.order_mode === 1
                        ? "Public"
                        : "Residential"}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={5} mt={2} sx={SpanStyle}>
                      Allergy
                    </Grid>
                    <Grid item md={1} mt={2}>
                      :
                    </Grid>
                    <Grid item md={6} mt={2}>
                      {orderInvoiceDetails.allergy === 0 ? "Yes" : "No"}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={5}>
                  <>
                    {orderInvoiceDetails && orderInvoiceDetails.order_type === 1
                      ? orderInvoiceDetails.food_details.map((row) => (
                          <Grid container key={row.food_name}>
                            <Grid item md={7} mt={2} sx={OrderStyle}>
                              {row.food_name} X {row.quantity}
                            </Grid>
                            <Grid item md={1} mt={2}>
                              :
                            </Grid>
                            <Grid item md={4} mt={2}>
                              {orderInvoiceDetails.currency}
                              {row.subtotal}
                            </Grid>
                          </Grid>
                        ))
                      : orderInvoiceDetails.food_details.map((row) => (
                          <Grid container key={row.food_name}>
                            <Grid item md={7} mt={2} sx={OrderStyle}>
                              {row.food_name} X {row.quantity}
                            </Grid>
                            <Grid item md={1} mt={2}>
                              :
                            </Grid>
                            <Grid item md={4} mt={2}>
                              {orderInvoiceDetails.currency}
                              {row.final_price}
                            </Grid>
                          </Grid>
                        ))}
                  </>
                  <Grid mt={4}>----------------------------</Grid>
                  <Grid container>
                    <Grid item md={7} mt={2} sx={SpanStyle}>
                      Item Total
                    </Grid>
                    <Grid item md={1} mt={2}>
                      :
                    </Grid>
                    <Grid item md={4} mt={2}>
                      {orderInvoiceDetails.currency}

                      {orderInvoiceDetails &&
                        (orderInvoiceDetails.order_type === 2
                          ? totalAmountt?.toFixed(2)
                          : totalAmounttforInstant.toFixed(2))}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={7} mt={2} sx={SpanStyle}>
                      Delivery Fee
                    </Grid>
                    <Grid item md={1} mt={2}>
                      :
                    </Grid>
                    <Grid item md={4} mt={2}>
                      {orderInvoiceDetails.delivery_type === 2 || orderInvoiceDetails.delivery_type === 3
                        ? orderInvoiceDetails.delivery_fee
                        : "No Delivery fee for other types"}

                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={7} mt={2} sx={SpanStyle}>
                      Tax amount
                    </Grid>
                    <Grid item md={1} mt={2}>
                      :
                    </Grid>
                    <Grid item md={4} mt={2}>
                      {orderInvoiceDetails.tax}
                    </Grid>
                  </Grid>
                  {orderInvoiceDetails.hasOwnProperty("tip") && (
                    <Grid container>
                      <Grid item md={7} mt={2} sx={SpanStyle}>
                        Tip amount
                      </Grid>
                      <Grid item md={1} mt={2}>
                        :
                      </Grid>
                      <Grid item md={4} mt={2}>
                        {orderInvoiceDetails.tip}
                      </Grid>
                    </Grid>
                  )}
                  {orderInvoiceDetails.hasOwnProperty("rewards") && (
                    <Grid container>
                      <Grid item md={7} mt={2} sx={SpanStyle}>
                        Reward amount
                      </Grid>
                      <Grid item md={1} mt={2}>
                        :
                      </Grid>
                      <Grid item md={4} mt={2}>
                        {orderInvoiceDetails.rewards}
                      </Grid>
                    </Grid>
                  )}
                  {orderInvoiceDetails.cancel_reason && (
                    <Grid container>
                      <Grid item md={7} mt={2} sx={SpanStyle}>
                        Cancel Reason
                      </Grid>
                      <Grid item md={1} mt={2}>
                        :
                      </Grid>
                      <Grid item md={4} mt={2}>
                        {orderInvoiceDetails.cancel_reason}
                      </Grid>
                    </Grid>
                  )}
                  <Grid container>
                    <Grid item md={7} mt={2} sx={SpanStyle}>
                      PDF
                    </Grid>
                    <Grid item md={1} mt={2}>
                      :
                    </Grid>
                    <Grid item md={4} mt={2}>
                      <a
                        href={orderInvoiceDetails.order_pdf} // replace with the actual key holding the PDF URL
                        target="_blank" // opens in a new tab
                        rel="noopener noreferrer" // security for opening links in new tab
                        sx={pdfLink}
                      >
                        View
                      </a>
                    </Grid>
                  </Grid>
                  <Grid mt={4}>----------------------------</Grid>
                  <Grid container>
                    <Grid item md={7} mt={2} sx={OrderTotalStyle}>
                      Total(Incl.VAT)
                    </Grid>
                    <Grid item md={1} mt={2}>
                      :
                    </Grid>
                    <Grid item md={4} mt={2} sx={OrderTotalStyle}>
                      {orderInvoiceDetails.currency}
                      {orderInvoiceDetails.order_type === 2
                        ? orderInvoiceDetails.total
                        : totalAmount?.toFixed(2)}
                    </Grid>
                  </Grid>
                  {orderReportDate && (
                    <Grid container>
                      <Grid item md={7} mt={2} sx={SpanStyle}>
                        Order Report Date
                      </Grid>
                      <Grid item md={1} mt={2}>
                        :
                      </Grid>
                      <Grid item md={4} mt={2}>
                        {formatCreatedAtToGMT(orderReportDate)}
                      </Grid>
                    </Grid>
                  )}
                  {orderMessage && (
                    <Grid container>
                      <Grid item md={7} mt={2} sx={SpanStyle}>
                        OrderReport
                      </Grid>
                      <Grid item md={1} mt={2}>
                        :
                      </Grid>
                      <Grid item md={12} mt={2} width={"100%"}>
                        {orderMessage}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        ) : (
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 26,
            }}
          >
            <Typography variant="h4" fontWeight={"bold"}>
              No orders Found
            </Typography>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default OrderModalComponent;
