import * as React from "react";
import {
  Button,
  Grid,
  Typography,
  TextField,
  FormControl,
  Container,
  Stack,
  Card,
  CardContent,
  
} from "@mui/material";
import { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { ManagementServices } from "../../services/management_services";
import {
  contactModal,
  modalHeading,
  info
} from "../../styles/common_styles";
import { SuccessAlert } from "../../components/alert";
import { ErrorAlert } from "../../components/alert";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm } from "react-hook-form";
import CenterMode from "../../components/carosual_component";
import headerChefLogo from "../../assets/informativepageImgs/images/headerChefLogo.png";
import headerUserLogo from "../../assets/informativepageImgs/images/headerUserLogo.png";
import headerMainimg from "../../assets/informativepageImgs/images/headerMainImg.png";
import chefImg from "../../assets/informativepageImgs/images/chefImg.png";
import veggies from '../../assets/images/veggies.png';
import infoveggies from '../../assets/informativepageImgs/images/infoveggies.png';
import infouserVeggies from '../../assets/informativepageImgs/images/infouserVeggies.png';
import inforMaps from '../../assets/informativepageImgs/images/infoMaps.png';
import InfouserImg from '../../assets/informativepageImgs/images/InfouserImg.png';
import InfoChefsMap from '../../assets/informativepageImgs/images/InfoChefsMap.png';
import momo from '../../assets/informativepageImgs/svgs/momo.svg';
import salad from '../../assets/informativepageImgs/svgs/salad.svg';
import pizza from '../../assets/informativepageImgs/svgs/pizza.svg';
import eggDoughnut from '../../assets/informativepageImgs/svgs/eggdoughnut.svg';
import IOSdownload from '../../assets/informativepageImgs/images/IOSdownload.png';
import Playstoredownload from '../../assets/informativepageImgs/images/Playstoredownload.png';
import PhoneIcon from '@mui/icons-material/Phone';
import UserProfile from '../../assets/informativepageImgs/images/userinfo.png';
import ChefProfile from '../../assets/informativepageImgs/images/chefprofile.png';
import Vegetables from '../../assets/informativepageImgs/images/vegetables.png';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Slider1 from '../../assets/informativepageImgs/images/slider1.png';
import Slider2 from '../../assets/informativepageImgs/images/slider2.png';
import Slider3 from '../../assets/informativepageImgs/images/slider3.png';
import { NavLink } from "react-router-dom";
const { header, section2, body1, body2, momosvg, saladsvg, eggsvg, pizzasvg, appsection } = info;
export const Homepage = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [name, setName] = React.useState();
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState();
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [passwordModal, setPasswordModal] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
 const [chefData, setChefData] = useState([]);
  const [consumerData, setConsumerData] = useState([]);
  const [sliderText, setSliderText] = useState([]);
  const [downloadText, setDownloadText] = useState([]);
  const [data, setData] = useState([]);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFieldChange = (e, feildName) => {
    if (feildName === "Name") {
      setName(e.target.value);
    } else if (feildName === "Email") {
      setEmail(e.target.value);
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailPattern.test(e.target.value);
      // Update email error state based on email validation
      setEmailError(!isValidEmail);
    } else {
      setMessage(e.target.value);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [currentIndex, setCurrentIndex] = useState(0);

  

  // Auto-slide effect (every 3 seconds)
 

  const goToIndex = (index) => {
    setCurrentIndex(index);
  };
  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };
  const images = [
    { url: Slider1, alt: 'Image 1',text: sliderText&&sliderText[0]?.heading,textPosition: { top: '20%', right: '0%',width:'30%',transform: 'translate(0%)'} },
    { url: Slider2, alt: 'Image 2',text:sliderText&&sliderText[1]?.heading,textPosition: { top: '20%', left:'0%',width:'30%',transform: 'translate(0%)' } },
    { url: Slider3, alt: 'Image 3',text:sliderText&&sliderText[2]?.heading ,textPosition: { top: '20%', left: '0%',width:'30%',transform: 'translate(0%)' } },
  ];
  const onSubmit = async (data) => {
    try {
      const { name, email, description } = data;
      const payload = {
        name: name,
        email: email,
        message: description, 
      };
      const contactUsDetails = await ManagementServices.InsertContactUsInfo(
        payload
      );

      if (contactUsDetails.status === 1) {
        setAlrtMsg("Thank you for contacting YouFood");
        setSuccessAlrtOpen(true);
        handleClose();
        reset();
      } else {
        setAlrtMsg(contactUsDetails.message);
        setErrorAlrtOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    if (password === "Krify@123") {
      setPasswordModal(false);
    } else {
      setError(true);
    }
  };
const getInformativeText = async () => {
    try {
      const response = await ManagementServices.viewInformativeText();

      if (response.status === 0 && response.data) {
        const section1 = response.data[1] || [];
        const section2 = response.data[2] || [];
        const section3 = response.data[3] || [];
        const section4 = response.data[4] || [];
        setChefData(section1);

        setConsumerData(section2);

        setSliderText(section3);

        setDownloadText(section4);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); 
    // Cleanup on unmount
  }, []);
  useEffect(() => {
    getInformativeText();
  },[]);

  return (
    <>
      {!passwordModal && (
        
        <>
        <Box sx={header}>
          <Container maxWidth={'xl'}>
          
            <Grid container justifyContent={'space-between'} alignItems={'center'} gap={2}>
              <Grid item md={2}>
              <img src={headerChefLogo} alt='cheflogo' style={{ maxWidth: "100%"}}/>
              </Grid>
              <Grid item md={7}>
                <img src={headerMainimg} alt='headermainImg' style={{ maxWidth: "100%", height: "auto" }} />
              </Grid>
              <Grid item md={2}>
              <img src={headerUserLogo} alt='userlogo' style={{ maxWidth: "100%", height: "auto" }}/>
              </Grid>
              

            </Grid>
          
          
         
        
          </Container>
        </Box>
        <Box sx={section2}>
          <Typography sx={{ fontSize:'45px', fontWeight:700, color:'#FFFF' }}>Embrace a reimagined approach to experiencing food</Typography>
        </Box>
        {/* Main content body1 */}
         <Box sx={body1} >
            {/* Background Image */}
          <Box
            component="img"
            src={infoveggies}
            alt="veggies"
            sx={{
              position: 'absolute',
              top: '5%',
              left: '35%',
              width: '730px',
              zIndex: 0,
            }}
          />
        <Container maxWidth='xl' >
          <Grid container sx={{pt:'107px'}}>
            <Grid item md={4}>
            <Stack gap={2}>
            {chefData.map((item, index) => (
              <Box key={index}>
                <Typography sx={{ fontSize: '30px', color: '#171717', fontWeight: 700 }}>
                  {item.heading} {/* Dynamically showing heading */}
                </Typography>
                <Typography sx={{ fontSize: '24px' }}>
                  <span style={{ fontWeight: 700 }}>Fact :</span> {item.content} {/* Dynamically showing content */}
                </Typography>
              </Box>
            ))}
          </Stack>
            </Grid>
            <Grid container item md={8} sx={{ zIndex:1, position:'relative' }} spacing={3}>
              <Grid item md={6}>
              <img src={chefImg} alt="chefimg" width='100%'/>
              </Grid>
              <Grid item md={6}>
              <img src={inforMaps} alt="inforMaps" width='100%'/>
              </Grid>
              <Box sx={momosvg}><img src={momo} alt="momo"/></Box>
              <Box sx={saladsvg}><img src={salad} alt="salad"/></Box>
            </Grid>
          </Grid>
        </Container>
        </Box>
        {/* Main content body2 */}
        <Box sx={body2} >
          <Box
            component="img"
            src={infouserVeggies}
            alt="veggies"
            sx={{
              position: 'absolute',
              top: '5%',
              left: '30%',
              width: '730px',
              zIndex: 0,
            }}
          />
        <Container maxWidth='xl' >
          <Grid container sx={{pt:'107px'}} justifyContent={'space-between'}>
            <Grid item md={3.5}>
            <Stack gap={2}>
            {consumerData.map((item, index) => (
              <Box key={index}>
                <Typography sx={{ fontSize: '30px', color: '#171717', fontWeight: 700 }}>
                  {item.heading} {/* Dynamically showing heading */}
                </Typography>
                <Typography sx={{ fontSize: '24px' }}>
                  <span style={{ fontWeight: 700 }}>Fact :</span> {item.content} {/* Dynamically showing content */}
                </Typography>
              </Box>
            ))}
          </Stack>
            </Grid>
            <Grid item marginBlockEnd={0.5}/>
            <Grid container item md={8} sx={{ zIndex:1, position:'relative' }} spacing={3}>
              <Grid item md={6}>
              <img src={InfoChefsMap} alt="chefimg" width='100%'/>
              </Grid>
              <Grid item md={6}>
              <img src={InfouserImg} alt="inforMaps" width='100%'/>
              </Grid>
              <Box
                sx={pizzasvg}><img src={pizza} alt="momo"/></Box>
                 <Box
                sx={eggsvg}><img src={eggDoughnut} alt="salad"/></Box>
            </Grid>
          </Grid>
        </Container>
        </Box>
        {/* Carousel part */}
        <Box sx={{ position: 'relative', width: '100%', height: '100%', maxHeight: '100%', overflow: 'hidden', padding: '50px 0' }}>
  <Box
    sx={{
      display: 'flex',
      transform: `translateX(-${currentIndex * 100}%)`, // Move by full width
      transition: 'transform 0.5s ease-in-out',
    }}
  >
    {images.map((image, index) => (
      <Box key={index} sx={{ flex: '0 0 100%', height: '100%', position: 'relative' }}>
        <img
          src={image.url}
          alt={image.alt}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
        {/* Overlay Text */}
        <Box
          sx={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            padding: '10px 20px',
            textAlign: 'center',
            height: '70%',
            display: 'flex',
            alignItems: 'center',
            ...image.textPosition
          }}
        >
          <Typography variant="h5" className="carouselText">{image.text}</Typography>
        </Box>
      </Box>
    ))}
  </Box>

  {/* Dots Indicator */}
  <Box
    sx={{
      position: 'absolute',
      bottom: '10px',
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    {images.map((_, index) => (
      <Box
        key={index}
        sx={{
          height: '10px',
          borderRadius: '50%',
          backgroundColor: currentIndex === index ? 'black' : 'grey',
          width: currentIndex === index ? '30px' : '10px',
          borderRadius: currentIndex === index ? '30px' : '10px',
          margin: '0 5px',
          cursor: 'pointer',
          transition: 'background-color 0.3s',
        }}
        onClick={() => goToIndex(index)}
      />
    ))}
  </Box>
        </Box>

        {/* App download cards */}
        <Box sx={appsection}>
          <Box sx={{position:'relative', height:'719px', display:'flex', alignItems:'center'}}>
            <Box sx={{position:'absolute', left:0, top:0, zIndex:0}}>
              <img src={veggies} alt="veggies"/>
            </Box>    
             <Box sx={{position:'absolute', right:0, bottom:0}}>
                <img src={veggies} alt="veggies"/>
            </Box>
            <Container maxWidth="xl">
              <Grid container spacing={3}>
                <Grid item md={6}>
                {/* Background container with blur and inset shadow */}
                  <div
                    style={{
                      height: "437.45px",
                      background: "rgba(255, 255, 255, 0.20)",
                      boxShadow:
                        "-10.93939208984375px 10.93939208984375px 10.93939208984375px rgba(173, 173, 173, 0.60) inset, 10.93939208984375px -10.93939208984375px 10.93939208984375px rgba(173, 173, 173, 0.60) inset, 10.93939208984375px 10.93939208984375px 10.93939208984375px rgba(173, 173, 173, 0.60) inset, -10.93939208984375px -10.93939208984375px 10.93939208984375px rgba(173, 173, 173, 0.60) inset",
                      borderRadius: "21px",
                      backdropFilter: "blur(24.62px)",
                      padding:'20px',
                      display:'flex',
                      alignItems:'center'
                    }}
                    >
                      <Grid container direction={'column'} spacing={2} alignContent={'center'}>
                        <Grid item sx={{display:'flex', justifyContent:'center'}}>
                        <Typography  variant="contained"  style={{
                            color: "black",
                            fontSize: "30px",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            lineHeight: "62.40px",
                            wordWrap: "break-word",
                            background:"#FFFFFF",
                            width:'120px',
                            height:'57px',
                            textAlign:'center',
                            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)' ,
                            borderRadius: '10px',
                        }}>CHEF</Typography>
                        </Grid> 
                        <Grid item sx={{display:'flex', justifyContent:'center'}}>
                        <Typography sx={{ fontSize:'26px', fontWeight:700, width:'547px', wordBreak:'break-word', textAlign:'center'}}>
                        {downloadText&&downloadText[0]?.heading}
                        </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={{ fontSize:'26px', fontWeight:900, display:'flex', justifyContent:'center'}}>Download YouFood Chef</Typography>
                        </Grid>
                        <Grid item container >
                        <Grid item md={6} textAlign={'right'}>
                          <Button>
                            <img src={Playstoredownload} alt="Playstore"/>
                          </Button>
                          </Grid>
                          <Grid item md={6}>
                            <Button>
                            <img src={IOSdownload} alt="IOS"/>
                            </Button>
                          </Grid>
                        </Grid>
                        </Grid>                
                  </div>
                </Grid>
                <Grid item md={6}>
                <div
                    style={{
                      height: "437.45px",
                      background: "rgba(255, 255, 255, 0.20)",
                      boxShadow:
                      "-10.93939208984375px 10.93939208984375px 10.93939208984375px rgba(173, 173, 173, 0.60) inset, 10.93939208984375px -10.93939208984375px 10.93939208984375px rgba(173, 173, 173, 0.60) inset, 10.93939208984375px 10.93939208984375px 10.93939208984375px rgba(173, 173, 173, 0.60) inset, -10.93939208984375px -10.93939208984375px 10.93939208984375px rgba(173, 173, 173, 0.60) inset",
                      borderRadius: "21px",
                      backdropFilter: "blur(24.62px)",
                      padding:'20px',
                      display:'flex',
                      alignItems:'center'
                    }}
                    >
                      <Grid container direction={'column'} spacing={2} alignContent={'center'}>
                        <Grid item sx={{display:'flex', justifyContent:'center'}}>
                        <Typography  variant="contained"  style={{
                            color: "black",
                            fontSize: "30px",
                            fontWeight: 700,
                            textTransform: "uppercase",
                            lineHeight: "62.40px",
                            wordWrap: "break-word",
                            background:"#FFFFFF",
                            width:'264px',
                            height:'57px',
                            textAlign:'center',
                            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)' ,
                            borderRadius: '10px',
                        }}>CONSUMER</Typography>
                        </Grid> 
                        <Grid item textAlign={'center'} sx={{display:'flex', justifyContent:'center'}}>
                          <Typography sx={{ fontSize:'26px', fontWeight:700, width:'547px', wordBreak:'break-word', textAlign:'center'}}>
                            {downloadText&&downloadText[1]?.heading}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography sx={{ fontSize:'26px', fontWeight:900, display:'flex', justifyContent:'center'}}>Download YouFood</Typography>
                        </Grid>
                        <Grid item container >
                        <Grid item md={6} textAlign={'right'}>
                          <Button>
                            <img src={Playstoredownload} alt="Playstore"/>
                          </Button>
                        </Grid>
                        <Grid item md={6}>
                          <Button>
                            <img src={IOSdownload} alt="IOS"/>
                          </Button>
                        </Grid>
                      </Grid>
                      </Grid>                
                  </div>
                </Grid>
                </Grid>
            </Container>
          </Box>
          <Box sx={{height:'700px'}}>
            <Container maxWidth={"xl"}>
            <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={5} >
              <Grid item md={4}>
                <img src={UserProfile} alt="user" style={{ maxWidth: "100%", height: "auto" }} />
              </Grid>
              <Grid item md={4} className="getInTouchContainer">
                <Grid item className="getInTouchText">
                  <Typography component={'h4'}>Get in touch now!</Typography>
                </Grid>
                <Grid item>
                  <img src={Vegetables} alt="Vegetables" style={{ maxWidth: "100%", height: "auto" }} />
                </Grid>
                <Grid item className="getInTouchButton">
                  <Button onClick={handleOpen}>CONTACT US</Button>
                </Grid>
              </Grid>
              <Grid item md={4}>
                <img src={ChefProfile} alt="ChefProfile" style={{ maxWidth: "100%", height: "auto" }} />
              </Grid>
            </Grid>
            </Container>
          </Box>
        </Box>
        <Box sx={{ bgcolor: "#0E0E0E" }}>
          <Container maxWidth={"xl"}>
            <Grid container spacing={5}>
                <Grid item md={3}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item md={6}>
                      <img 
                        src={headerChefLogo} 
                        alt="chef" 
                        style={{ maxWidth: "100%", height: "auto" }} 
                      />
                    </Grid>
                    <Grid item md={6}>
                      <img 
                        src={headerUserLogo} 
                        alt="consumer" 
                        style={{ maxWidth: "100%", height: "auto" }} 
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3} className="footerBlock">
                  <Typography component={'h4'}>Get to Know Us</Typography>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography component={NavLink} to="/terms_and_conditions">Terms & Conditions</Typography>
                    </Grid>
                    <Grid item>
                      <Typography component={NavLink} to="/privacy_policy">Privacy Policy</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3} className="footerBlock">
                  <Typography component={'h4'}>Contact Us</Typography>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography component="a" href="tel:(555) 123-4567"><PhoneIcon />&nbsp;(555) 123-4567</Typography>
                    </Grid>
                    <Grid item>
                      <Typography component="a" href="mailto:info@youfood.com"><MailOutlineIcon />&nbsp;info@youfood.com</Typography>
                    </Grid>
                  </Grid>
                </Grid>
               <Grid item md={3} className="appiconsStyles">
                  <Grid container direction="column" spacing={1}>
                    <Grid item >
                      <img src={IOSdownload} alt="IOS"/>
                    </Grid>
                    <Grid item>
                      <img src={Playstoredownload} alt="Playstore"/>
                    </Grid>
                  </Grid>
               </Grid>
            </Grid>
            <Grid className="downloadAppText">
              <Typography >Download the apps for Terms&Conditions and Privacy policy</Typography>
            </Grid>
            <Grid className="copyrightText">
              <Typography>Copyright © 2025 All Rights Reserved</Typography>
            </Grid>

          </Container>
        </Box>
        </>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={contactModal}>
          <Grid item>
            <Typography sx={modalHeading}>CONTACT US</Typography>
          </Grid>
          <Grid container justifyContent={"center"} spacing={3}>
            <Grid item md={10}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      {...register("name", { required: "Name is required" })}
                      fullWidth
                      label="Name"
                      size="small"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^\S+@\S+$/i,
                          message: "Invalid email address",
                        },
                      })}
                      fullWidth
                      label="Email"
                      size="small"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextareaAutosize
                      {...register("description", {
                        required: "Description is required",
                      })}
                      rowsMin={5}
                      placeholder="Enter Your Description"
                      style={{ width: "100%", height: 200, borderRadius: 10 ,padding: 10}}
                      error={!!errors.description}
                    />
                    {errors.description && (
                      <Typography color="error" variant="caption">
                        {errors.description.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ backgroundColor: "black" }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <SuccessAlert
        alertMsg={alrtMsg}
        open={successAlrtOpen}
        close={closeSuccess}
      />
      <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
      <Modal
        open={passwordModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Authentication
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={(event) => handlePasswordSubmit(event)}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  width="100%"
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    setError(false);
                    setPassword(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {error && (
                  <p style={{ color: "red" }}>Please enter valid password</p>
                )}
              </FormControl>
              <Grid
                sx={{ width: "100%", textAlign: "center", padding: "10px" }}
              >
                <Button variant="contained" type="submit" size="medium">
                  submit
                </Button>
              </Grid>
            </form>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
