import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  TextareaAutosize,
  Stack,
} from "@mui/material";
import { TitleComponent } from "../../components/title_component";
import { ButtonComponent } from "../../components/form_components/button";
import { SuccessAlert, ErrorAlert } from "../../components/alert";
import { ManagementServices } from "../../services/management_services";
const InformativePage = () => {
  const [chefData, setChefData] = useState([]);
  const [consumerData, setConsumerData] = useState([]);
  const [sliderText, setSliderText] = useState([]);
  const [downloadText, setDownloadText] = useState([]);
 const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };

  const handleChange = (e, section, index) => {
    const { name, value } = e.target;

    switch (section) {
      case "chef":
        const updatedChefData = [...chefData];
        updatedChefData[index] = {
          ...updatedChefData[index],
          [name]: value,
        };
        setChefData(updatedChefData);
        break;
      case "consumer":
        const updatedConsumerData = [...consumerData];
        updatedConsumerData[index] = {
          ...updatedConsumerData[index],
          [name]: value,
        };
        setConsumerData(updatedConsumerData);
        break;
      case "slider":
        const updatedSliderData = [...sliderText];
        updatedSliderData[index] = {
          ...updatedSliderData[index],
          [name]: value,
        };
        setSliderText(updatedSliderData);
        break;
      default:
        break;
    }
  };

  // Handle Save
  const getInformativeText = async () => {
    try {
      const response = await ManagementServices.viewInformativeText();

      if (response.status === 0 && response.data) {
        const section1 = response.data[1] || [];
        const section2 = response.data[2] || [];
        const section3 = response.data[3] || [];
        const section4 = response.data[4] || [];

        setChefData(section1);
        setConsumerData(section2);
        setSliderText(section3);
        setDownloadText(section4);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Save Updated Data to API
  const handleSave = async (type) => {
    const questions =
      type === 1
        ? chefData
        : type === 2
        ? consumerData
        : type === 3
        ? sliderText
        : downloadText;

    const cleanedQuestions = questions.map(({ type, ...rest }) => rest);
    const payload = {
      type: type,
      questions: cleanedQuestions,
    };
    try {
      const response = await ManagementServices.updateInformativeText(payload);
      if (response.status === 0) {
        setAlrtMsg(response.message);
          setSuccessAlrtOpen(true);
      } else {
        setAlrtMsg(response.message);
        setErrorAlrtOpen(true);
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  useEffect(() => {
    getInformativeText();
  }, []);

  return (
    <>
    
    <Grid container sx={{ padding: 3 }}>
      <Grid
        item
        md={12}
        container
        justifyContent="space-between"
        alignItems="center"
        height="10vh"
      >
        <Grid item container>
          <TitleComponent title="Informative Text" />
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Typography variant="h6" mt={2} mb={1} sx={{ fontWeight: "bold" }}>
          Chef
        </Typography>
        <Grid container spacing={2}>
          <Grid
            item
            md={9}
            sx={{
              border: "1px solid #E0E0E0",
              borderRadius: "10px",
              padding: "20px",
              margin: "20px",
            }}
          >
            <Stack spacing={2}>
              {chefData.map((item, index) => (
                <React.Fragment key={item.id}>
                  <TextField
                    name="heading"
                    fullWidth
                    placeholder="Enter Your Text..."
                    value={item.heading}
                    onChange={(e) => handleChange(e, "chef", index)}
                  />
                  <TextareaAutosize
                    name="content"
                    className="textArea"
                    style={{ width: "97%" }}
                    placeholder="Enter Description"
                    value={item.content}
                    onChange={(e) => handleChange(e, "chef", index)}
                  />
                </React.Fragment>
              ))}
            </Stack>
          </Grid>
          <Grid item md={2}>
            <ButtonComponent btnText="Save" onClick={() => handleSave(1)} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <Typography variant="h6" mt={2} mb={1} sx={{ fontWeight: "bold" }}>
          Consumer
        </Typography>
        <Grid container spacing={2}>
          <Grid
            item
            md={9}
            sx={{
              border: "1px solid #E0E0E0",
              borderRadius: "10px",
              padding: "20px",
              margin: "20px",
            }}
          >
            <Stack spacing={2}>
              {consumerData.map((item, index) => (
                <React.Fragment key={item.id}>
                  <TextField
                    name="heading"
                    fullWidth
                    placeholder="Enter Your Text..."
                    value={item.heading}
                    onChange={(e) => handleChange(e, "consumer", index)}
                  />
                  <TextareaAutosize
                    name="content"
                    className="textArea"
                    style={{ width: "97%" }}
                    placeholder="Enter Description"
                    value={item.content}
                    onChange={(e) => handleChange(e, "consumer", index)}
                  />
                </React.Fragment>
              ))}
            </Stack>
          </Grid>
          <Grid item md={2}>
            <ButtonComponent btnText="Save" onClick={() => handleSave(2)} />
          </Grid>
        </Grid>
      </Grid>

      {/* Slider Section */}
      <Grid item md={12}>
        <Typography variant="h6" mt={2} mb={1} sx={{ fontWeight: "bold" }}>
          Slider
        </Typography>
        <Grid container spacing={2}>
          <Grid
            item
            md={9}
            sx={{
              border: "1px solid #E0E0E0",
              borderRadius: "10px",
              padding: "20px",
              margin: "20px",
            }}
          >
            <Stack spacing={2}>
              {sliderText.map((item, index) => (
                <React.Fragment key={item.id}>
                  <Typography
                    variant="h6"
                    mt={2}
                    mb={1}
                    sx={{ fontWeight: "bold" }}
                  >
                    Slider {index + 1}
                  </Typography>
                  <TextareaAutosize
                    name="content"
                    className="textArea"
                    style={{ width: "97%" }}
                    placeholder="Enter Description"
                    value={item.heading}
                    onChange={(e) => handleChange(e, "slider", index)}
                  />
                </React.Fragment>
              ))}
            </Stack>
          </Grid>
          <Grid item md={2}>
            <ButtonComponent btnText="Save" onClick={() => handleSave(3)} />
          </Grid>
        </Grid>
      </Grid>

      {/* Download Section */}
      <Grid item md={12}>
        <Typography variant="h6" mt={2} mb={1} sx={{ fontWeight: "bold" }}>
          Download
        </Typography>
        <Grid container spacing={2}>
          <Grid
            item
            md={9}
            sx={{
              border: "1px solid #E0E0E0",
              borderRadius: "10px",
              padding: "20px",
              margin: "20px",
            }}
          >
            <Stack spacing={2}>
              {downloadText.map((item, index) => (
                <React.Fragment key={item.id}>
                  <Typography
                    variant="h6"
                    mt={2}
                    mb={1}
                    sx={{ fontWeight: "bold" }}
                  >
                    {index === 0 ? "Chef" : "Consumer"}
                  </Typography>
                  <TextareaAutosize
                    name="content"
                    className="textArea"
                    style={{ width: "97%" }}
                    placeholder="Enter Description"
                    value={item.heading}
                    onChange={(e) => handleChange(e, "download", index)}
                  />
                </React.Fragment>
              ))}
            </Stack>
          </Grid>
          <Grid item md={2}>
            <ButtonComponent btnText="Save" onClick={() => handleSave(4)} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <SuccessAlert
      alertMsg={alrtMsg}
      open={successAlrtOpen}
      close={closeSuccess}
    />
    <ErrorAlert
      alertMsg={alrtMsg}
      open={errorAlrtOpen}
      close={closeError}
    />
    </>
  );
};

export default InformativePage;
