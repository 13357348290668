import React, { useEffect } from "react";
import { Container, Grid,Typography } from "@mui/material";
import { ManagementServices } from "../../services/management_services";

export const InformativeTermsAndConditions = () => {
    const [termsAndConditions, setTermsAndConditions] = React.useState([]);
    const getTermsAndConditions = async () => {
        const payload ={
            type:1
        }
        try {
           const response = await ManagementServices.InformativePrivacyPloicy(payload);
           if(response.status === 1){
                setTermsAndConditions(response.data[0].description)
            }
        }
        catch (error) {
            console.error(`Error occured while fetching roles:${error}`);
        }
    }
    useEffect(() => {
        getTermsAndConditions();   
    },[])
    
    return (
        <>   
            <Container maxWidth="lg" sx={{mt:5}}>  
                <Grid>
                    <h1 align="center" gutterBottom>
                        Terms And Conditions
                    </h1>
                    <Typography variant="body1" sx={{ whiteSpace: "pre-line",mt:5 }}>
                        {termsAndConditions}
                    </Typography>
                </Grid>
            </Container>
        </>
    )
};