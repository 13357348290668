import AxiosClient from "../axios/client";
import { StorageClient } from "../utils/helpers";
import { GetAdminDetails } from "../utils/helpers";
import { GetRoleDetails } from "../utils/helpers";

export const ManagementServices = {
  getRoleWiseUserDetails: async ({
    min,
    max,
    searchValue,
    blocked,
    unBlocked,
    edited,
    deleted,
    isPending,
    country,
    state,
    city,
  }) => {
    try {
      const adminDetails = await GetAdminDetails();

      const roleId = await StorageClient.getItem("previlageId");
      const rolesWiseDetails = await AxiosClient.post(
        "getRoleWiseUserDetails",
        {
          min,
          max,
          roleId,
          userId: adminDetails.id,
          searchValue,
          blocked,
          unBlocked,
          edited,
          deleted,
          isPending,
          country,
          state,
          city,
        },
        { useAccessToken: true }
      );

      return rolesWiseDetails;
    } catch (error) {
      console.log(`Error occured while getting globalOperations:${error}`);
    }
  },

  getConsumerDetails: async ({
    min,
    max,
    search,
    blocked,
    edited,
    deleted,
    isPending,
    country,
    state,
    city,
  }) => {
    try {
      const adminDetails = await GetAdminDetails();
      const roleDetails = await GetRoleDetails();
      const roleId = roleDetails.role_id;
      const consumerDetails = await AxiosClient.post(
        "getConsumerList",
        {
          min,
          max,
          roleId,
          userId: adminDetails.id,
          search,
          blocked,
          edited,
          deleted,
          isPending,
          country,
          state,
          city,
        },
        { useAccessToken: true }
      );
      return consumerDetails;
    } catch (error) {
      console.log(`Error occured while getting consumer details:${error}`);
    }
  },

  deleteAdmin: async (userId) => {
    try {
      const adminDetails = await GetAdminDetails();
      const userRoleId = await StorageClient.getItem("previlageId");
      const deleteUser = await AxiosClient.post(
        "raiseDeleteUserRequest",
        {
          userId,
          userRoleId,
          adminId: adminDetails.id,
        },
        {
          useAccessToken: true,
        }
      );
      return deleteUser;
    } catch (error) {
      console.log(`Error occured while deleting:${error}`);
    }
  },

  blockAdmin: async (userId) => {
    try {
      const adminDetails = await GetAdminDetails();
      const userRoleId = await StorageClient.getItem("previlageId");
      const deleteUser = await AxiosClient.post(
        "raiseBlockUserRequest",
        {
          userId,
          userRoleId,
          adminId: adminDetails.id,
        },
        {
          useAccessToken: true,
        }
      );
      return deleteUser;
    } catch (error) {
      console.log(`Error occured while blocking:${error}`);
    }
  },

  unblockAdmin: async (userId) => {
    try {
      const adminDetails = await GetAdminDetails();
      const userRoleId = await StorageClient.getItem("previlageId");
      const unblockUser = await AxiosClient.post(
        "raiseUnBlockUserRequest",
        {
          userId,
          userRoleId,
          adminId: adminDetails.id,
        },
        {
          useAccessToken: true,
        }
      );
      return unblockUser;
    } catch (error) {
      console.log(`Error occured while blocking:${error}`);
    }
  },

  consumerAboutDetails: async (userId) => {
    try {
      const consumerAboutDetails = await AxiosClient.post(
        "consumerAboutDetails",
        {
          userId,
        },
        {
          useAccessToken: true,
        }
      );
      return consumerAboutDetails;
    } catch (error) {
      console.log(`Error occured while viewUserdetails:${error}`);
    }
  },

  deleteConsumer: async (userId) => {
    try {
      const adminDetails = await GetAdminDetails();
      const roleDetails = await GetRoleDetails();
      const userRoleId = roleDetails.role_id;
      const deleteConsumer = await AxiosClient.post(
        "deleteConsumer",
        {
          userId,
          userRoleId,
          adminId: adminDetails.id,
        },
        {
          useAccessToken: true,
        }
      );
      return deleteConsumer;
    } catch (error) {
      console.log(`Error occured while deleting:${error}`);
    }
  },

  blockConsumer: async (userId) => {
    try {
      const adminDetails = await GetAdminDetails();
      const roleDetails = await GetRoleDetails();
      const userRoleId = roleDetails.role_id;
      // const userRoleId = await StorageClient.getItem("previlageId");
      const BlockUser = await AxiosClient.post(
        "blockConsumer",
        {
          userId,
          userRoleId,
          adminId: adminDetails.id,
        },
        {
          useAccessToken: true,
        }
      );

      return BlockUser;
    } catch (error) {
      console.log(`Error occured while blocking:${error}`);
    }
  },

  unblockConsumer: async (userId) => {
    try {
      const adminDetails = await GetAdminDetails();
      const roleDetails = await GetRoleDetails();
      const userRoleId = roleDetails.role_id;
      const unblockconsumer = await AxiosClient.post(
        "unBlockConsumer",
        {
          userId,
          userRoleId,
          adminId: adminDetails.id,
        },
        {
          useAccessToken: true,
        }
      );
      return unblockconsumer;
    } catch (error) {
      console.log(`Error occured while blocking:${error}`);
    }
  },

  editAdmin: async (payload) => {
    try {
      const editUser = await AxiosClient.post("raiseEditUserRequest", payload, {
        useAccessToken: true,
      });
      return editUser;
    } catch (error) {
      console.log(error);
    }
  },

  consumerOnBoarding: async (payload) => {
    try {
      const consumerOnBoardingAdd = await AxiosClient.post(
        "insertConsumerOnboarding",
        payload,
        {
          useAccessToken: true,
        }
      );
      return consumerOnBoardingAdd;
    } catch (error) {
      console.log(error);
    }
  },

  getChefList: async ({
    min,
    max,
    search,
    blocked,
    edited,
    deleted,
    isPending,
    country,
    state,
    city,
  }) => {
    try {
      const adminDetails = await GetAdminDetails();
      const roleDetails = await GetRoleDetails();
      const roleId = roleDetails.role_id;
      const ChefDetails = await AxiosClient.post(
        "getChefList",
        {
          min,
          max,
          roleId,
          userId: adminDetails.id,
          search,
          blocked,
          edited,
          deleted,
          isPending,
          country,
          state,
          city,
        },
        { useAccessToken: true }
      );
      return ChefDetails;
    } catch (error) {
      console.log(`Error occured while getting chef details:${error}`);
    }
  },

  chefAboutDetails: async (userId) => {
    try {
      const consumerAboutDetails = await AxiosClient.post(
        "chefAboutDetails",
        {
          userId,
        },
        {
          useAccessToken: true,
        }
      );
      return consumerAboutDetails;
    } catch (error) {
      console.log(`Error occured while chef view details:${error}`);
    }
  },

  chefOnBoarding: async (payload) => {
    try {
      const chefOnBoardingAdd = await AxiosClient.post(
        "insertChefOnboarding",

        payload,

        {
          useAccessToken: true,
        }
      );
      return chefOnBoardingAdd;
    } catch (error) {
      console.log(error);
    }
  },

  blockChef: async (chefId) => {
    try {
      const adminDetails = await GetAdminDetails();
      const roleDetails = await GetRoleDetails();
      const userRoleId = roleDetails.role_id;
      // const userRoleId = await StorageClient.getItem("previlageId");
      const BlockChef = await AxiosClient.post(
        "blockChef",
        {
          chefId,
          userRoleId,
          adminId: adminDetails.id,
        },
        { useAccessToken: true }
      );

      return BlockChef;
    } catch (error) {
      console.log(`Error occured while blocking:${error}`);
    }
  },

  unblockChef: async (chefId) => {
    try {
      const adminDetails = await GetAdminDetails();
      const roleDetails = await GetRoleDetails();
      // const userRoleId = await StorageClient.getItem("previlageId");
      const userRoleId = roleDetails.role_id;
      const unblockchef = await AxiosClient.post(
        "unblockChef",
        {
          chefId,
          userRoleId,
          adminId: adminDetails.id,
        },
        { useAccessToken: true }
      );
      return unblockchef;
    } catch (error) {
      console.log(`Error occured while blocking:${error}`);
    }
  },

  deleteChef: async (chefId) => {
    try {
      const adminDetails = await GetAdminDetails();
      const roleDetails = await GetRoleDetails();
      // const userRoleId = await StorageClient.getItem("previlageId");
      const userRoleId = roleDetails.role_id;
      const deleteChef = await AxiosClient.post(
        "deleteChef",
        {
          chefId,
          userRoleId,
          adminId: adminDetails.id,
        },
        { useAccessToken: true }
      );
      return deleteChef;
    } catch (error) {
      console.log(`Error occured while deleting:${error}`);
    }
  },

  getIncompleteonboardingDetails: async (payload) => {
    try {
      const adminDetails = await GetAdminDetails();
      const incompleteconsumerlist = await AxiosClient.post(
        "getConsumerIncompleteOnboardingList",
        { ...payload, userId: adminDetails.id },
        { useAccessToken: true }
      );
      return incompleteconsumerlist;
    } catch (error) {
      console.log(`Error occured while getting globalOperations:${error}`);
    }
  },

  getIncompleteonboardingDetailsChef: async (payload) => {
    try {
      const adminDetails = await GetAdminDetails();
      const incompletecheflist = await AxiosClient.post(
        "getChefIncompleteOnboardingList",
        { ...payload, userId: adminDetails.id },
        { useAccessToken: true }
      );

      return incompletecheflist;
    } catch (error) {
      console.log(`Error occured while getting globalOperations:${error}`);
    }
  },

  getOnBoardingList: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const gettingList = await AxiosClient.post("getOnboardingList", payload, {
        useAccessToken: true,
      });
      return gettingList;
    } catch (error) {
      console.log(error);
    }
  },

  onBoardingItemDelete: async (actionId, fromModule) => {
    try {
      const itemDelete = await AxiosClient.post(
        "deleteOnboarding",
        {
          onboardingId: actionId,
          type: fromModule,
        },
        {
          useAccessToken: true,
        }
      );
      return itemDelete;
    } catch (error) {
      console.log(error);
    }
  },

  updateOnboardingSteps: async (updatedSteps) => {
    try {
      const updatedStepsData = await AxiosClient.post(
        "updateOnboardingSteps",
        {
          onboardingSteps: updatedSteps,
        },
        {
          useAccessToken: true,
        }
      );
      return updatedStepsData;
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  },

  deletePendingOnboardingConsumer: async (userId) => {
    try {
      const deletependingconsumer = await AxiosClient.post(
        "deletePendingOnboardingConsumers",
        {
          userId,
        },
        {
          useAccessToken: true,
        }
      );
      return deletependingconsumer;
    } catch (error) {
      console.log(`Error occured while deleting:${error}`);
    }
  },

  deletePendingOnboardingChef: async (userId) => {
    try {
      const deletependingchef = await AxiosClient.post(
        "deletePendingOnboardingChef",
        {
          userId,
        },
        {
          useAccessToken: true,
        }
      );
      return deletependingchef;
    } catch (error) {
      console.log(`Error occured while deleting:${error}`);
    }
  },

  editOnboarding: async (updatedData) => {
    try {
      const editOnboarding = await AxiosClient.post(
        "editOnboarding",
        updatedData,
        {
          useAccessToken: true,
        }
      );
      return editOnboarding;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  insertFaq: async (payload) => {
    try {
      const insertFaq = await AxiosClient.post("insertFaq", payload, {
        useAccessToken: true,
      });
      return insertFaq;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  editFaq: async (payload) => {
    try {
      const editFaq = await AxiosClient.post("editFaq", payload, {
        useAccessToken: true,
      });
      return editFaq;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  listFaqs: async (payload) => {
    try {
      const listFaqs = await AxiosClient.post("listFaqs", payload, {
        useAccessToken: true,
      });
      return listFaqs;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  deleteFaq: async (faqId) => {
    try {
      const deleteFaq = await AxiosClient.post(
        "deleteFaq",
        { faqId },
        {
          useAccessToken: true,
        }
      );
      return deleteFaq;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  TermsandConditions: async (payload) => {
    try {
      const listTerms = await AxiosClient.post(
        "listTermsAndConditions",
        payload,
        {
          useAccessToken: true,
        }
      );
      return listTerms;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  deleteTermsandConditions: async (id) => {
    try {
      const deleteTerms = await AxiosClient.post(
        "deleteTermsAndConditions",
        { id },
        {
          useAccessToken: true,
        }
      );
      return deleteTerms;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  inerstTermandConditionsAdding: async (payload) => {
    try {
      const insertTermsandConditions = await AxiosClient.post(
        "InsertTermsAndConditions",
        payload,
        {
          useAccessToken: true,
        }
      );
      return insertTermsandConditions;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  listPrivacyPolicies: async (payload) => {
    try {
      const listPrivacyPolicies = await AxiosClient.post(
        "listPrivacyPolicies",
        payload,
        {
          useAccessToken: true,
        }
      );
      return listPrivacyPolicies;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  deletePrivacyPolicy: async (policyId) => {
    try {
      const deletePrivacyPolicy = await AxiosClient.post(
        "deletePrivacyPolicy",
        { policyId },
        {
          useAccessToken: true,
        }
      );
      return deletePrivacyPolicy;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  InsertPrivacyPolicy: async (payload) => {
    try {
      const InsertPrivacyPolicy = await AxiosClient.post(
        "InsertPrivacyPolicy",
        payload,
        {
          useAccessToken: true,
        }
      );
      return InsertPrivacyPolicy;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  editTermsandCondition: async (payload) => {
    try {
      const editFaq = await AxiosClient.post(
        "editTermsAndConditions",
        payload,
        {
          useAccessToken: true,
        }
      );
      return editFaq;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  editPrivacyPolicy: async (payload) => {
    try {
      const editFaq = await AxiosClient.post("editPrivacyPolicy", payload, {
        useAccessToken: true,
      });
      return editFaq;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  userDeleteApproval: async (actionId) => {
    try {
      const adminDetails = await GetAdminDetails();
      const roleDetails = await GetRoleDetails();
      const roleId = roleDetails.id;
      const userRoleId = await StorageClient.getItem("previlageId");
      const deleteApproval = await AxiosClient.post(
        "userDeleteApproval",
        {
          userId: actionId,
          admin_id: adminDetails.id,
          adminRoleId: roleId,
          userRoleId: userRoleId,
        },
        {
          useAccessToken: true,
        }
      );
      return deleteApproval;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  GetPricingPlans: async (payload) => {
    try {
      const getPricingPlans = await AxiosClient.post(
        "getPricingPlans",
        payload,
        {
          useAccessToken: true,
        }
      );
      return getPricingPlans;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getPricingLocationData: async (payload) => {
    try {
      const getPricingPlans = await AxiosClient.post(
        "getPricingPlans",
        payload,
        {
          useAccessToken: true,
        }
      );
      return getPricingPlans;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  //EditPricingPlan Api Calling
  EditGlobalPricing: async (payload) => {
    const adminDetails = await GetAdminDetails();
    const requestPayload = {
      ...payload,
      userId: adminDetails.id,
    };

    try {
      const editprice = await AxiosClient.post(
        "addPricingPlan",
        requestPayload,
        {
          useAccessToken: true,
        }
      );
      return editprice;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  //DeletePricingPlan Api Calling
  DeletePricingPlan: async (payload) => {
    const adminDetails = await GetAdminDetails();
    const requestPayload = {
      ...payload,
      userId: adminDetails.id,
    };

    try {
      const deletePricing = await AxiosClient.post(
        "deletePricingPlan",
        requestPayload,
        {
          useAccessToken: true,
        }
      );
      return deletePricing;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  RewardsListing: async (payload) => {
    try {
      const RewardsList = await AxiosClient.get("listRewards", {
        useAccessToken: true,
      });
      return RewardsList;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  InsertRewards: async (payload) => {
    try {
      const InsertRewards = await AxiosClient.post("insertReward", payload, {
        useAccessToken: true,
      });
      return InsertRewards;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  DeleteRewards: async (id) => {
    try {
      const DeleteRewards = await AxiosClient.post(
        "deleteReward",
        { id },
        {
          useAccessToken: true,
        }
      );
      return DeleteRewards;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  locationsData: async (payload) => {
    try {
      const locations = await AxiosClient.post(
        "getPricingLocationData",
        payload,
        {
          useAccessToken: true,
        }
      );
      return locations;
    } catch (err) {
      console.error(err, "err");
    }
  },

  updateRewards: async (payload) => {
    try {
      const upDateRewards = await AxiosClient.post("editReward", payload, {
        useAccessToken: true,
      });
      return upDateRewards;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  updateUserProfile: async (payload) => {
    try {
      const EditProfile = await AxiosClient.post("editProfile", payload, {
        useAccessToken: true,
      });
      return EditProfile;
    } catch (error) {
      console.log(error);
    }
  },

  getNotificationCountFunc: async () => {
    const adminDetails = await GetAdminDetails();
    const requestPayload = {
      userId: adminDetails.id,
      type: "typeWise",
    };

    try {
      const notificationDetails = await AxiosClient.post(
        "getNotificationCount",
        requestPayload,
        {
          useAccessToken: true,
        }
      );
      return notificationDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  updateNoificationStatus: async (type) => {
    const adminDetails = await GetAdminDetails();
    const requestPayload = {
      userId: adminDetails.id,
      type: type,
    };

    try {
      const notificationStatusDetails = await AxiosClient.post(
        "updateNotificationReadStatus",
        requestPayload,
        {
          useAccessToken: true,
        }
      );
      return notificationStatusDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  userActionApproval: async (payload) => {
    try {
      const adminDetails = await GetAdminDetails();
      const roleDetails = await GetRoleDetails();
      const roleId = roleDetails.id;
      const userRoleId = await StorageClient.getItem("previlageId");
      const userActionApproval = await AxiosClient.post(
        "userActionApproval",

        {
          admin_id: adminDetails.id,
          adminRoleId: roleId,
          userRoleId: userRoleId,
          ...payload,
        },
        {
          useAccessToken: true,
        }
      );
      return userActionApproval;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  IncompleteOnboardingMessageToConsumer: async (payload) => {
    try {
      const consumerMessage = await AxiosClient.post(
        "sendIncompleteOnboardingMessageToConsumer",
        payload,
        {
          useAccessToken: true,
        }
      );
      return consumerMessage;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  IncompleteOnboardingMessageToChef: async (payload) => {
    try {
      const chefMessage = await AxiosClient.post(
        "sendIncompleteOnboardingMessageToChef",
        payload,
        {
          useAccessToken: true,
        }
      );
      return chefMessage;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  CommunicationDetails: async (payload) => {
    try {
      const communicationMessages = await AxiosClient.post(
        "listCommunications",
        payload,
        {
          useAccessToken: true,
        }
      );
      return communicationMessages;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  InsertCommunication: async (payload) => {
    try {
      const AddCommunication = await AxiosClient.post(
        "insertCommunication",
        payload,
        {
          useAccessToken: true,
        }
      );
      return AddCommunication;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  InsertContactUsInfo: async (payload) => {
    try {
      const insertContactus = await AxiosClient.post(
        "insertContactUs",
        payload,
        {
          useAccessToken: false,
        }
      );
      return insertContactus;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getContactusDetails: async (payload) => {
    try {
      const contactUsDetails = await AxiosClient.post(
        "listContactUs",
        payload,
        {
          useAccessToken: true,
        }
      );
      return contactUsDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getConsumerOrderDetails: async (payload) => {
    try {
      const consumerOrderDetails = await AxiosClient.post(
        "consumerOrderDetails",
        payload,
        {
          useAccessToken: true,
        }
      );
      return consumerOrderDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getconsumerOrderInvoiceDetails: async (payload) => {
    try {
      const consumerOrderinovoiceDetails = await AxiosClient.post(
        "consumerOrderInvoiceDetails",
        payload,
        {
          useAccessToken: true,
        }
      );
      return consumerOrderinovoiceDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  getChefOrderDetails: async (payload) => {
    try {
      const chefOrderDetails = await AxiosClient.post(
        "chefOrderDetails",
        payload,
        {
          useAccessToken: true,
        }
      );
      return chefOrderDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getTotalOrders: async (payload) => {
    try {
      const TotalOrders = await AxiosClient.post("completeOrders", payload, {
        useAccessToken: true,
      });
      return TotalOrders;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  handleupdateCountryConversion: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const countryCoversion = await AxiosClient.post(
        "updateCountryConversion",
        payload,
        {
          useAccessToken: true,
        }
      );
      return countryCoversion;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getcountryCurrency: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const countryCurrency = await AxiosClient.post(
        "countryWiseCurrency",
        payload,
        {
          useAccessToken: true,
        }
      );
      return countryCurrency;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getcountryTax: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const countryTax = await AxiosClient.post("countryWiseTax", payload, {
        useAccessToken: true,
      });
      return countryTax;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  handleupdateCountryTax: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const countryCoversionTax = await AxiosClient.post(
        "updateCountryTax",
        payload,
        {
          useAccessToken: true,
        }
      );
      return countryCoversionTax;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  reviewDetails: async (payload) => {
    try {
      const Reviews = await AxiosClient.post("getReviews", payload, {
        useAccessToken: true,
      });
      return Reviews;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  bulkOnboardingInsertConsumer: async (payload) => {
    try {
      const bulkOnboarding = await AxiosClient.post(
        "bulkInsertConsumerOnboarding",
        payload,
        {
          useAccessToken: true,
        }
      );
      return bulkOnboarding;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  bulkOnboardingInsertChef: async (payload) => {
    try {
      const bulkOnboarding = await AxiosClient.post(
        "bulkInsertChefOnboarding",
        payload,
        {
          useAccessToken: true,
        }
      );
      return bulkOnboarding;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  bulkFaqInsert: async (payload) => {
    try {
      const bulkOnboarding = await AxiosClient.post("bulkInsertFaq", payload, {
        useAccessToken: true,
      });
      return bulkOnboarding;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  getOperationalDetails: async () => {
    const adminDetails = await GetAdminDetails();
    const payload = {
      userId: adminDetails.id,
    };
    try {
      const getOperationalData = await AxiosClient.post(
        "dashboardOperationsCount",
        payload,
        {
          useAccessToken: true,
        }
      );
      return getOperationalData;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getRoleDetailview: async (payload) => {
    try {
      const getViewRoleDetails = await AxiosClient.post(
        "adminDetails",
        payload,
        {
          useAccessToken: true,
        }
      );
      return getViewRoleDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getAllConsumersList: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const getConsumerlistDetails = await AxiosClient.post(
        "totalConsumers",
        payload,
        {
          useAccessToken: true,
        }
      );
      return getConsumerlistDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getAllChefsList: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const getcheflistDetails = await AxiosClient.post("totalChefs", payload, {
        useAccessToken: true,
      });
      return getcheflistDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  sendNotifications: async (payload) => {
    try {
      const sendNotificationsforusers = await AxiosClient.post(
        "sendBulkNotification",
        payload,
        {
          useAccessToken: true,
        }
      );
      return sendNotificationsforusers;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getTotalIncompleteOrders: async (payload) => {
    try {
      const TotalOrders = await AxiosClient.post("inCompleteOrders", payload, {
        useAccessToken: true,
      });
      return TotalOrders;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  getChefandConsumerandFoodItems: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const TotalOrders = await AxiosClient.post(
        "getTopConsumersChefsAndFoods",
        payload,
        {
          useAccessToken: true,
        }
      );
      return TotalOrders;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getTicketDetails: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const getAllTickets = await AxiosClient.post(
        "getTicketsForConsumerOrChef",
        payload,
        {
          useAccessToken: true,
        }
      );
      return getAllTickets;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getViewTicketDetails: async (payload) => {
    try {
      const viewTicketDetails = await AxiosClient.post(
        "viewTicketDetails",
        payload,
        {
          useAccessToken: true,
        }
      );
      return viewTicketDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getStoreCreditDetails: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const viewTicketDetails = await AxiosClient.post(
        "storeCreditList",
        payload,
        {
          useAccessToken: true,
        }
      );
      return viewTicketDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getViewStoreTicketDetails: async (payload) => {
    try {
      const viewTicketDetails = await AxiosClient.post(
        "storeCreditView",
        payload,
        {
          useAccessToken: true,
        }
      );
      return viewTicketDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getOrdersBreakdownDetails: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const viewTicketDetails = await AxiosClient.post(
        "ordersBreakDown",
        payload,
        {
          useAccessToken: true,
        }
      );
      return viewTicketDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getRevenueData: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const viewTicketDetails = await AxiosClient.post(
        "dashboardRevenue",
        payload,
        {
          useAccessToken: true,
        }
      );
      return viewTicketDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  getExportPdfRevenueData: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;

    try {
      const viewTicketDetails = await AxiosClient.post("createPdf", payload, {
        useAccessToken: true,
        responseType: "blob",
      });
      return viewTicketDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  ticketAssign: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const assignTicketDetails = await AxiosClient.post(
        "assignTicket",
        payload,
        {
          useAccessToken: true,
        }
      );
      return assignTicketDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  declineTicket: async (payload) => {
    try {
      const declineTicketDetails = await AxiosClient.post(
        "rejectTicket",
        payload,
        {
          useAccessToken: true,
        }
      );
      return declineTicketDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  approveTicket: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const approveTicketDetails = await AxiosClient.post(
        "approveTicket",
        payload,
        {
          useAccessToken: true,
        }
      );
      return approveTicketDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  closeTicket: async (payload) => {
    try {
      const closeTicketDetails = await AxiosClient.post(
        "closeTicket",
        payload,
        {
          useAccessToken: true,
        }
      );
      return closeTicketDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getroleDetailsforMessages: async (payload) => {
    try {
      const MessagesRoleDetails = await AxiosClient.post(
        "getUserDetailsForTicket",
        payload,
        {
          useAccessToken: true,
        }
      );
      return MessagesRoleDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getOrderDetailsReports: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const orderReportDetails = await AxiosClient.post(
        "orderReports",
        payload,
        {
          useAccessToken: true,
        }
      );
      return orderReportDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  OrderMessageDetailsReports: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const orderReportDetails = await AxiosClient.post(
        "reportedChefMessages",
        payload,
        {
          useAccessToken: true,
        }
      );
      return orderReportDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  OrderMessageViewDetails: async (payload) => {
    try {
      const orderReportMessageDetails = await AxiosClient.post(
        "reportedMsgView",
        payload,
        {
          useAccessToken: true,
        }
      );
      return orderReportMessageDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  updateTicketStatusDetails: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const updateTicketStatus = await AxiosClient.post(
        "updateTicketReadStatus",
        payload,
        {
          useAccessToken: true,
        }
      );
      return updateTicketStatus;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  pricingPlansDownload: async () => {
    const payload = {};
    try {
      const pricingPlansDownloadDetails = await AxiosClient.post(
        "pricingPlanExcelDownload",
        payload,
        {
          useAccessToken: true,
        }
      );
      return pricingPlansDownloadDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },

  dynamixTextDetails: async (payload) => {
    try {
      const dynamicText = await AxiosClient.post(
        "dynamicTextAddEditDelete",
        payload,
        {
          useAccessToken: true,
        }
      );
      return dynamicText;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  commercialKitchenList: async (payload) => {
    try {
      const commercialKitchenList = await AxiosClient.post(
        "getCommercialKitchenList",
        payload,
        {
          useAccessToken: true,
        }
      );
      return commercialKitchenList;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  addcommercialKitchenList: async (payload) => {
    try {
      const addcommercialKitchenList = await AxiosClient.post(
        "addCommercialKitchen",
        payload,
        {
          useAccessToken: true,
        }
      );
      return addcommercialKitchenList;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  deletecommercialKitchenItem: async (payload) => {
    try {
      const deletecommercialKitchenList = await AxiosClient.post(
        "deleteCommercialKitchen",
        payload,
        {
          useAccessToken: true,
        }
      );
      return deletecommercialKitchenList;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  editcommercialKitchenItem: async (payload) => {
    try {
      const editcommercialKitchenList = await AxiosClient.post(
        "editCommercialKitchen",
        payload,
        {
          useAccessToken: true,
        }
      );
      return editcommercialKitchenList;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  getTicketStatusUpdate: async (payload) => {
    try {
      const getTicketDetails = await AxiosClient.post(
        "getTicketStatus",
        payload,
        {
          useAccessToken: true,
        }
      );
      return getTicketDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  uploadCurrencyConversionRate: async (payload) => {
    try {
      const uploadCurrrencyRates = await AxiosClient.post(
        "currencyExcelUpload",
        payload,
        {
          useAccessToken: true,
        }
      );
      return uploadCurrrencyRates;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  verifyWithEmailOrPhone: async (payload) => {
    try {
      const verifyEmaialOrPhone = await AxiosClient.post(
        "verifyEmailOrPhone",
        payload,
        {
          useAccessToken: true,
        }
      );
      return verifyEmaialOrPhone;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  verifyOtpSend: async (payload) => {
    try {
      const verifyOtp = await AxiosClient.post("verifyOtp", payload, {
        useAccessToken: true,
      });
      return verifyOtp;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  DeleteUser: async (payload) => {
    try {
      const DeleteUserAccount = await AxiosClient.post(
        "deleteUserAccount",
        payload,
        {
          useAccessToken: true,
        }
      );
      return DeleteUserAccount;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  revenueListForChef: async (payload) => {
    try {
      const DeleteUserAccount = await AxiosClient.post(
        "chefRevenueLimitList",
        payload,
        {
          useAccessToken: true,
        }
      );
      return DeleteUserAccount;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  addRevenueForChef: async (payload) => {
    try {
      const addcommercialKitchenList = await AxiosClient.post(
        "createChefRevenueLimit",
        payload,
        {
          useAccessToken: true,
        }
      );
      return addcommercialKitchenList;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  editRevenueItem: async (payload) => {
    try {
      const editRevenueList = await AxiosClient.post(
        "editChefRevenueLimit",
        payload,
        {
          useAccessToken: true,
        }
      );
      return editRevenueList;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  deleteRevenueLimitItem: async (payload) => {
    try {
      const deletecommercialKitchenList = await AxiosClient.post(
        "deleteChefRevenueLimit",
        payload,
        {
          useAccessToken: true,
        }
      );
      return deletecommercialKitchenList;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  viewInformativeText: async (payload) => {
    try {
      const viewInformativeText = await AxiosClient.post(
        "informativeSite",
        payload,
        {
          useAccessToken: true,
        }
      );
      return viewInformativeText;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  updateInformativeText: async (payload) => {
    try {
      const updateInformativeText = await AxiosClient.post(
        "informativeSiteUpdate",
        payload,
        {
          useAccessToken: true,
        }
      );
      return updateInformativeText;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  InformativePrivacyPloicy: async (payload) => {
    try {
      const InformativePrivacyPloicy = await AxiosClient.post(
        "privacyPolicyInformativeSite",
        payload,
        {
          useAccessToken: true,
        }
      );
      return InformativePrivacyPloicy;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
  changePassword: async (payload) => {
    const adminDetails = await GetAdminDetails();
    payload.userId = adminDetails.id;
    try {
      const changePasswordDetails = await AxiosClient.post(
        "updatePassword",
        payload,
        {
          useAccessToken: true,
        }
      );
      return changePasswordDetails;
    } catch (error) {
      console.error(`error occured${error}`);
    }
  },
};
